import React, { useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonList,
  IonCol,
  IonGrid,
  IonSegmentButton,
  IonLoading,
  IonRow,
  IonSegment,
  IonCardHeader,
  IonTitle,
  IonItemDivider,
  IonIcon,
} from "@ionic/react";
import { ellipsisHorizontalOutline } from "ionicons/icons";
import Content from "../components/Content";
import CompactSongList from "./CompactSongList";
import ArtistItem from "./ArtistItem";
import Spacer from "./Spacer";
import { useFavorites } from "../data/store/useFavorites";
import { useProfile } from "../data/store/useProfile";
import { usePlaylists } from "../data/store/usePlaylists";
import { usePageTitle } from "../util/sugar";

const displayNoItemsCard = (
  <IonCard>
    <IonCardHeader color="tertiary">
      <IonTitle>No Favorites Found!</IonTitle>
    </IonCardHeader>
    <IonCardContent>
      <IonItemDivider color="clear" />
      Use the Song Menu Modal to add favorites to remember your favorite songs
      or artists! this is a personal list that other users can't see!
      <IonItemDivider color="clear" />
      The button for the song menu looks like this:
      <IonIcon
        size="large"
        icon={ellipsisHorizontalOutline}
        style={{ marginLeft: 10, verticalAlign: "middle" }}
      />
    </IonCardContent>
  </IonCard>
);

const Favorites: React.FC = () => {
  const { favorites, isLoading, followList } = useFavorites();
  const unauthenticated = useProfile((x) => x.status === "unauthenticated");
  const { status } = usePlaylists();
  const [listState, setListState] = useState<string>("songs");
  usePageTitle(listState === "songs" ? "Favorite Songs" : "Favorite Artists");

  const toggleDisplay = (val: any) => {
    setListState(val.detail.value);
  };

  if (unauthenticated) {
    return <Content>You have no access to see this page</Content>;
  }

  return isLoading ? (
    <IonLoading isOpen={isLoading}></IonLoading>
  ) : (
    <Content>
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <IonSegment value={listState} onIonChange={toggleDisplay}>
              <IonSegmentButton value="songs">Songs</IonSegmentButton>
              <IonSegmentButton value="artists">Artists</IonSegmentButton>
            </IonSegment>
            <Spacer height={10} />
          </IonCol>
        </IonRow>
        <IonRow>
          {listState === "songs" && favorites && (
            <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
              {!!favorites.length ? (
                <CompactSongList
                  status={status}
                  fetchMore={() => {}}
                  songRefs={favorites}
                />
              ) : (
                displayNoItemsCard
              )}
            </IonCol>
          )}

          {listState === "artists" && followList && (
            <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
              {!!followList.length ? (
                <>
                  <Spacer height={24} />
                  <IonList>
                    {followList.map(({ id }) => (
                      <ArtistItem key={id} id={id} />
                    ))}
                  </IonList>
                </>
              ) : (
                displayNoItemsCard
              )}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default Favorites;
