import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { withRouter } from 'react-router'
import {
  IonAvatar,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItemDivider,
  IonRow,
  useIonViewWillLeave,
  IonText,
  IonLabel,
  IonButton,
  IonSegmentButton,
  IonSegment,
} from '@ionic/react'
import { globeOutline, personAddOutline, personRemove } from 'ionicons/icons'
import SongList from '../components/SongList'
import Spacer from '../components/Spacer'
import PersonalAlbums from '../components/PersonalAlbums'
import { useArtists } from '../data/store/useArtists'
import { useFavorites } from '../data/store/useFavorites'
import { useProfile } from '../data/store/useProfile'
import { useSongs } from '../data/store/useSongs'
import { useTitle } from '../data/store/useTitle'
import { externalLink, sanitize, useCssImage, useIdParam } from '../util/sugar'
import './Avatar.scss'

const ArtistDetail: React.FC = (props) => {
  const id = useIdParam(props)
  const { fetchSongsForArtist, status } = useSongs()
  const { fetchMeta, shouldFetchMeta } = useArtists()
  const { ownerId, authorId } = useProfile()
  const artist = useArtists((x) => x.retrieve(id))
  const songs = useSongs((x) => x.retrieveByArtist(id))
  const artistsSongsNumber = useSongs((x) => x.artistNumberSongs)
  const setTitle = useTitle((x) => x.setTitle)
  const { isFollowing, follow, unfollow } = useFavorites()
  const [songsList, setSongsList] = useState(songs)
  const [loadedArtistId, setLoadedArtistId] = useState('')
  const [avatarStatus, setAvatarStatus] = useState('hidden')
  const [bgLoaded, setbgLoaded] = useState(false)
  const [artistState, setArtistState] = useState('songs')
  const bgRef = useRef<HTMLIonContentElement>(null)

  const following = isFollowing(id)
  const totalSongsNumber = artistsSongsNumber[id]
  const isArtistWallExist =
    artist && artist.ownerId && artist.wall && artist.wall !== ''
  const artistWall = artist && artist.wall
  const wall = isArtistWallExist ? artistWall : '/assets/img/void.png'
  const bgUri = useCssImage(wall)
  const alias = artist && artist.alias
  const current = loadedArtistId === id

  const toggleArtistState = ({ detail: { value } }: any) => {
    setArtistState(value)
  }

  useIonViewWillLeave(() => {
    setLoadedArtistId('')
  })

  useEffect(() => {
    alias && setTitle(alias)
  }, [alias, setTitle])

  useIonViewWillLeave(() => {
    setLoadedArtistId('')
  })

  useLayoutEffect(() => {
    shouldFetchMeta(id) && fetchMeta(id)
    fetchSongsForArtist(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    alias && setTitle(alias)
  }, [alias, setTitle])

  useEffect(() => {
    artist && setLoadedArtistId(artist.id)
    setbgLoaded(false)
  }, [artist])

  useEffect(() => {
    const background = bgRef.current
    if (background && wall && artist && !bgLoaded) {
      const image = new Image()
      image.onload = function () {
        if (background) {
          background.style.background = bgUri
        }
        setbgLoaded(true)
      }
      image.src = wall
    }
  }, [bgLoaded, bgRef, bgUri, artist, wall])

  useEffect(() => {
    setSongsList(songs)
  }, [songs])

  return (
    <IonContent
      style={{ '--ion-background-color': 'clear' }}
      className={
        bgLoaded
          ? artist && 'artist-wall sadfam-content'
          : 'artist-bg sadfam-content'
      }
      ref={bgRef}
    >
      <Spacer height={50} />

      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <IonCardContent
              style={{
                background: 'clear',
                borderRadius: '8px',
                margin: '0 16px',
              }}
              className="centerColumn"
            >
              {current && artist && artist.avatar && (
                <IonAvatar
                  className={`${avatarStatus} `}
                  style={{
                    width: 108,
                    height: 108,
                  }}
                >
                  <IonImg
                    onIonImgDidLoad={() => setAvatarStatus('loaded')}
                    src={artist && artist.avatar}
                    alt={artist && alias}
                  />
                </IonAvatar>
              )}

              <IonCardTitle className="ion-text-center">
                <IonText
                  color="dark"
                  style={{
                    textShadow: 'none',
                  }}
                >
                  {current && artist && artist.statement}
                </IonText>
              </IonCardTitle>

              <IonItemDivider color="clear" />

              {current && artist && artist.website && (
                <a
                  className="avatarLink"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={externalLink(sanitize(artist.website))}
                >
                  <IonText color="tertiary">{artist.website}</IonText>

                  <IonIcon
                    color="tertiary"
                    icon={globeOutline}
                    style={{ margin: 'auto 10px' }}
                  />
                </a>
              )}

              <IonItemDivider color="clear" />

              {current && id !== authorId && (
                <>
                  <IonButton
                    style={{
                      margin: '0 auto',
                    }}
                    fill="outline"
                    onClick={() =>
                      following ? unfollow(ownerId, id) : follow(ownerId, id)
                    }
                  >
                    {following ? (
                      <IonIcon
                        slot="start"
                        color="primary"
                        icon={personRemove}
                      />
                    ) : (
                      <IonIcon slot="start" icon={personAddOutline} />
                    )}
                    <IonLabel>
                      {following ? 'Unfollow' : 'Follow'} {alias}
                    </IonLabel>
                  </IonButton>

                  <IonItemDivider color="clear" />
                </>
              )}

              <IonSegment value={artistState} onIonChange={toggleArtistState}>
                <IonSegmentButton value="songs">Songs</IonSegmentButton>
                <IonSegmentButton value="albums">Albums</IonSegmentButton>
              </IonSegment>
            </IonCardContent>

            {artistState === 'songs' ? (
              current && songsList ? (
                <SongList
                  status={status}
                  songRefs={songsList}
                  hideFetchButton={songsList.length === totalSongsNumber}
                  fetchMore={() => fetchSongsForArtist(id)}
                />
              ) : (
                <></>
              )
            ) : (
              <>
                <Spacer height={30} />
                <PersonalAlbums id={(artist && artist.ownerId) || ''} />
              </>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default withRouter(ArtistDetail)
