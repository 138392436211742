import create from "zustand";
import { ArtistId } from "../../models/Artist";
import { ActivityService } from "../../services/ActivityService";

export type ActivityActions = {
    transmitActivity: (activity: "typing" | "listening", collection: string, id: string, artistId: ArtistId, isActive: boolean) => void,
}


export const useActivity = create<ActivityActions>(() => ({
    transmitActivity: async (activity, collection, id, artist, isActive) => {
        if (activity === 'typing') {
            await ActivityService.transmitTypingStatus(id, collection, artist, isActive);
        }
        else if (activity === 'listening') {
            await ActivityService.transmitListeningStatus(id, collection, artist, isActive);
        }

    },
}));