import React, { useEffect } from "react";
import { IonCol, IonGrid, IonList, IonLoading, IonRow } from "@ionic/react";
import Content from "../components/Content";
import SongItem from "../components/SongItem";
import { useSongs } from "../data/store/useSongs";
import { useIdParam, usePageTitle } from "../util/sugar";

interface SongRevisionsProps {
  id?: string;
}

const SongRevisions: React.FC<SongRevisionsProps> = (props) => {
  const id = useIdParam(props);
  const { fetchRevisions, status } = useSongs();
  const revisions = useSongs((x) => x.revisions);

  usePageTitle("Revisions");

  useEffect(() => {
    !revisions.map((x) => x.id).includes(id) && fetchRevisions(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content>
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <IonList>
              {revisions.map((revision) => (
                <SongItem key={revision.id} id={revision.id} />
              ))}
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonLoading isOpen={status === "fetching"} />
    </Content>
  );
};

export default SongRevisions;
