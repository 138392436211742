import React, { useEffect, useRef, useState } from "react";
import { useProfile } from "../data/store/useProfile";
import P2PAudioService from "../services/P2PAudioService";

interface p2pMicProps {
    onReady: (() => void)
}

const P2PAudioIO: React.FC<p2pMicProps> = ({ onReady }) => {
    const { ownerId } = useProfile()
    const localAudioInputRef = useRef<HTMLAudioElement>(null);
    const remoteAudioOutputRef = useRef<HTMLAudioElement>(null);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (open || !ownerId) {
            return;
        }
        localAudioInputRef.current && remoteAudioOutputRef.current && P2PAudioService.please().openUserMedia(localAudioInputRef.current, remoteAudioOutputRef.current).then(() => {
            setOpen(true);
            onReady();
        });
    }, [onReady, open, ownerId]);
    return <>
        <audio playsInline muted autoPlay id="input" controls ref={localAudioInputRef} />
        <audio playsInline autoPlay id="output" controls ref={remoteAudioOutputRef} />
    </>
}

export default P2PAudioIO;