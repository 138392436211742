import { IonButton, IonCard, IonCardHeader } from '@ionic/react';
import React, { memo } from 'react';
import Content from '../components/Content';
import SongItem from '../components/SongItem';
import MediaService from '../services/MediaService';


const Visualizer: React.FC = () => {
    return (
       <></>
    );
};



export default memo(Visualizer);
