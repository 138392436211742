import produce from "immer";
import create from "zustand";
import { ArtistId } from "../../models/Artist";
import {
  FreshSong,
  MediaReference,
  Song,
  SongId,
  SongRef,
} from "../../models/Song";
import { Tag } from "../../models/Tag";
import { EndOfTime, Timestamp } from "../../models/Timeline";
import { ArtistService } from "../../services/ArtistService";
import { SongService } from "../../services/SongService";
import { compareTimestamps } from "../../util/sugar";

export type songHookStatus = "fetching" | "idle" | "initial" | "deleting";

export type SongDirectory = {
  timeline: SongRef[];
  tagged?: SongRef[];
  artists: Record<ArtistId, SongRef[]>;
  artistNumberSongs: Record<ArtistId, number>;
  cursor: Timestamp;
  pageSize: number;
  songs: Record<SongId, Song>;
  status: songHookStatus;
  empty: SongId[];
  all: SongId[];
  revisions: SongRef[];
  observe: () => void;
  shouldFetch: (id: SongId) => boolean;
  retrieve: (id: SongId) => Song | undefined;
  retrieveByArtist: (id: ArtistId) => SongRef[] | undefined;
  fetchSongsForArtist: (id: ArtistId) => void;
  updateSong: (id: SongId, song: FreshSong) => void;
  fetchSongsForHashtag: (tag: Tag) => void;
  fetch: (id: SongId) => void;
  delete: (id: SongId) => void;
  revise: (
    id: SongId,
    description: string,
    media: MediaReference,
    title: string,
    authors: ArtistId[]
  ) => void;
  fetchSome: () => void;
  fetchRevisions: (id: SongId) => void;
  oldest: () => Timestamp;
  oldestFromArtist: (id: ArtistId) => Timestamp;
};

export const useSongs = create<SongDirectory>((set, get) => ({
  timeline: [],
  all: [],
  revisions: [],
  artists: {},
  artistNumberSongs: {},
  cursor: EndOfTime,
  status: "initial",
  pageSize: 10,
  songs: {} as Record<SongId, Song>,
  empty: [],
  delete: (id) => {
    set({ status: "deleting" });
    SongService.delete(id).then(() => {
      const newState = produce(get(), (draft) => {
        draft.timeline = get().timeline.filter((item) => item.id !== id);
        delete draft.songs[id];
        draft.status = "idle";
      });

      set(newState);
    });
  },
  observe: () => {
    SongService.observe(
      (song) => {
        const nextState = produce(get(), (draftState) => {
          draftState.songs[song.id] = song;
          if (!draftState.timeline.map((x) => x.id).includes(song.id))
            draftState.timeline.unshift(SongService.toRef(song));
          draftState.status = "idle";
        });
        set(nextState);
      },
      (revokedSongId) => {
        const revokedState = produce(get(), (draftState) => {
          delete draftState.songs[revokedSongId];
          draftState.timeline = draftState.timeline.filter(
            (x) => x.id !== revokedSongId
          );
          draftState.status = "idle";
        });
        set(revokedState);
      }
    );
  },
  oldestFromArtist: (id) => {
    const artistSongs = get().artists[id];
    if (artistSongs === undefined || artistSongs.length === 0) {
      return { seconds: -1, nanoseconds: -1 };
    }
    return artistSongs[artistSongs.length - 1].updatedAt;
  },
  revise: async (id, description, media, revisionTitle, authors) => {
    const songRepo = get();
    const songs = produce(songRepo.songs, (draftState) => {
      draftState[id].original = id;
    });
    set({ songs, status: "fetching" });
    const revisedSong = get().songs[id];
    const { ownerId } = revisedSong;
    const title = revisedSong.title + " (" + revisionTitle + ")";
    const freshRevisedSong = {
      description,
      media,
      title,
      authors,
      ownerId,
    } as FreshSong;
    SongService.revise(id, freshRevisedSong)
      .then((newRevision) => {
        const nextState = produce(get(), (draftState) => {
          draftState.songs[newRevision.id] = newRevision;
          draftState.all.push(newRevision.id);
          draftState.revisions = [];
          draftState.revisions.push(SongService.toRef(revisedSong));
          draftState.revisions.push(SongService.toRef(newRevision));
          draftState.status = "idle";
        });
        set(nextState);
      })
      .catch(() => {
        set({ status: "idle" });
      });
  },
  oldest: () => {
    let timeline = [...get().timeline];
    if (timeline.length === 0) {
      return { seconds: -1, nanoseconds: -1 };
    }
    console.log(timeline);
    return timeline.sort((x, y) => x.updatedAt.seconds - y.updatedAt.seconds)[0]
      .updatedAt;
  },
  fetchRevisions: async (songId) => {
    set({ status: "fetching" });

    return new Promise<Song[]>(() => {
      SongService.fetchRevisions(songId)
        .then((songs) => {
          const revisions = songs.map((x) => SongService.toRef(x));
          const nextState = produce(get(), (draftState) => {
            for (const song of songs.reverse()) {
              draftState.songs[song.id] = song;
            }
            draftState.revisions = revisions;
            draftState.status = "idle";
          });
          set(nextState);
        })
        .catch(() => {
          set({ status: "idle" });
        });
    });
  },
  fetchSome: async () => {
    const directory = get();
    set({ status: "fetching" });
    console.log(directory.oldest());
    try {
      const songs = await SongService.fetchMore(
        directory.pageSize,
        directory.oldest()
      );
      const nextState = produce(get(), (draftState:any) => {
        for (const song of songs.reverse()) {
          draftState.songs[song.id] = song;
          if (!draftState.timeline.map((x:any) => x.id).includes(song.id)) {
            draftState.timeline.push(SongService.toRef(song));
          }
        }
        draftState.status = "idle";
      });
      set(nextState);
    } catch {
      set({ status: "idle" });
    }
  },
  fetchSongsForHashtag: async (tag) => {
    try {
      const tagged = await SongService.fetchSongsWithHashtag(tag);
      const taggedRefs = tagged.map(({ id, updatedAt }) => {
        return { id, updatedAt };
      });
      const nextState = produce(get(), (draftState) => {
        for (const song of tagged) {
          draftState.songs[song.id] = song;
        }
        draftState.tagged = taggedRefs;
        draftState.status = "idle";
      });
      set(nextState);
      const taggedComments = await SongService.fetchSongsWithHashtagInComments(
        tag
      );
      const nextStateWithMoreTags = produce(get(), (draftState) => {
        for (const song of tagged) {
          draftState.songs[song.id] = song;
        }
        draftState.tagged = [...taggedRefs, ...taggedComments];
        draftState.status = "idle";
      });
      set(nextStateWithMoreTags);
    } catch {
      set({ status: "idle" });
    }
  },
  fetchSongsForArtist: async (id) => {
    const directory = get();

    set({ status: "fetching" });
    try {
      const songs = await ArtistService.fetchMoreSongs(
        id,
        directory.oldestFromArtist(id),
        directory.pageSize
      );
      const songsNumber = await ArtistService.fetchArtistSongsNumber(id);

      const nextState = produce(get(), (draftState) => {
        if (draftState.artists[id] === undefined) {
          draftState.artists[id] = songs.map((x) => SongService.toRef(x));
        } else {
          for (const song of songs) {
            if (!draftState.artists[id].map((x) => x.id).includes(song.id))
              draftState.artists[id].push(SongService.toRef(song));
          }
        }
        draftState.artists[id].sort((x, y) =>
          compareTimestamps(y.updatedAt, x.updatedAt)
        );
        for (const song of songs) draftState.songs[song.id] = song;

        draftState.artistNumberSongs[id] = songsNumber;
        draftState.status = "idle";
      });
      set(nextState);
    } catch {
      set({ status: "idle" });
    }
  },
  updateSong: (id: string, song: FreshSong) => {
    set({ status: "fetching" });
    const {
      updatedAt: { seconds, nanoseconds },
    } = song;

    SongService.updateSong(id, song)
      .then((freshSong) => {
        const nextState = produce(get(), (draftState) => {
          draftState.songs[id] = {
            ...freshSong,
            id,
          };
          draftState.status = "idle";
        });
        set(nextState);
      })
      .catch(() => {
        set({ status: "idle" });
      });
  },
  shouldFetch: (id) => {
    const directory = get();
    return !directory.all.includes(id) && !directory.empty.includes(id);
  },
  retrieve: (id) => {
    const directory = get();
    return directory.songs[id];
  },
  retrieveByArtist: (id) => {
    const directory = get();
    return directory.artists[id];
  },
  fetch: async (id) => {
    const directory = get();
    const exists = directory.songs[id];

    if (exists !== undefined) return;
    set({ status: "fetching" });

    try {
      const song = await SongService.fetch(id);
      // Add Fetched Entries to directory
      // and push to empty state if fetched song was already deleted by author
      const nextState = produce(get(), (draftState) => {
        if (song.id) {
          song.authors.forEach((item) => {
            if (
              draftState.artists[item] &&
              !get().artists[item].some((item) => item.id === song.id)
            ) {
              draftState.artists[item].push(song);
            } else {
              draftState.artists[item] = [song];
            }
          });

          draftState.songs[song.id] = song;
          draftState.all.push(song.id);
        } else {
          draftState.empty.push(id);
        }

        draftState.status = "idle";
      });
      set(nextState);
    } catch {
      set({ status: "idle" });
    }
  },
}));
