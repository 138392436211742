import { SongId } from "../models/Song";
import { OwnerId } from "../models/User";
import { toDateSeconds, uuidv4 } from "../util/sugar";
import { ArtistService } from "./ArtistService";

interface S3UploadParams {
  fields: {
    AWSAccessKeyId: string;
    acl: string;
    key: string;
    policy: string;
    signature: string;
  };
  fileUrl: string;
  uploadUrl: string;
}

export class TransmissionService {
  static FetchTodaysTransmission = (ownerId: OwnerId) => {
    return new Promise<SongId | null>((resolve, reject) => {
      ArtistService.fetchLatestSong(ownerId)
        .then((song) => {
          const today = new Date().setHours(0, 0, 0, 0);
          const thatDay = new Date(toDateSeconds(song.updatedAt)).setHours(
            0,
            0,
            0,
            0
          );
          if (today === thatDay) {
            resolve(song.id);
          } else {
            resolve(null);
          }
        })
        .catch(reject);
    });
  };

  static GeneratePreSignedURL = (
    ownerId: string,
    collection: string,
    fileExtension: string
  ): S3UploadParams => {
    const key =
      collection + "/" + ownerId + "/" + uuidv4() + "." + fileExtension;
    return {
      fields: {
        AWSAccessKeyId: "AKIA2OEWYLRN65ROYYNH",
        acl: "private",
        key: key,
        filename: key,
        policy:
          "ewogICJleHBpcmF0aW9uIjogIjIwMjYtMTEtMTFUMDA6MDA6MDBaIiwKICAiY29uZGl0aW9ucyI6IFsKICAgIHsiYnVja2V0IjogInNvbmdhZGF5cyJ9LAogICAgWyJzdGFydHMtd2l0aCIsICIka2V5IiwgIiJdLAogICAgeyJhY2wiOiAicHJpdmF0ZSJ9LAogICAgWyJzdGFydHMtd2l0aCIsICIkQ29udGVudC1UeXBlIiwgIiJdLAogICAgWyJzdGFydHMtd2l0aCIsICIkZmlsZW5hbWUiLCAiIl0sCiAgICBbImNvbnRlbnQtbGVuZ3RoLXJhbmdlIiwgMCwgNTI0Mjg4MDAwXQogIF0KfQ==",
        // eslint-disable-next-line no-useless-escape
        signature: "Hj/byeDzXjdqDXxDlU9JHcpGVFQ=",
        "Content-Type": collection,
      },
      fileUrl: "https://songadays.s3.amazonaws.com/" + key,
      uploadUrl: "https://songadays.s3.amazonaws.com/",
    } as S3UploadParams;
  };
}
