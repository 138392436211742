import produce from "immer";
import create from "zustand";
import { ArtistId, PlaylistOrder } from "../../models/Artist";
import { OwnerId } from "../../models/User";
import { LoginService } from "../../services/LoginService";
import { ProfileService } from "../../services/ProfileService";
import { SettingsService } from "../../services/SettingsService";

export type ProfileState = {
  darkMode: boolean;
  status: "unknown" | "authenticating" | "authenticated" | "unauthenticated";
  authenticated: boolean;
  ownerId: OwnerId;
  authorId?: ArtistId;
  menuEnabled: boolean;
  observeAuthentication: (
    onAuthenticated: (artistId: ArtistId, ownerId: string) => void,
    onUnAuthed: (artistId: ArtistId) => void
  ) => void;
  setDarkMode: (isOn: boolean) => void;
  changeProfile: (item: string, value: string) => void;
  changeUserPlaylistOrder: (item: string, value: PlaylistOrder[]) => void;
};

export const useProfile = create<ProfileState>((set, get) => ({
  menuEnabled: true,
  status: "unknown",
  darkMode: false,
  authenticated: false,
  ownerId: "",
  changeProfile: (item, value: string) => {
    const { authorId, ownerId } = get();
    let change = { ownerId } as any;
    change[item] = value;
    authorId && ProfileService.changeProfile(change, authorId);
  },
  changeUserPlaylistOrder: (fieldName: string, value: PlaylistOrder[]) => {
    const { authorId, ownerId } = get();
    let change = { ownerId } as any;
    change.playlistsOrder = { [fieldName]: value };

    authorId && ProfileService.changeProfile(change, authorId);
  },
  setDarkMode: (isOn) => {
    const { ownerId } = get();
    set({ darkMode: isOn });
    const darkmodeSetting = { theme: isOn ? "dark" : "light" };
    ownerId && SettingsService.changeSetting(darkmodeSetting, ownerId);
  },
  observeAuthentication: (onAuthenticated, onUnAuthed) => {
    const authenticate = async (authorId: ArtistId, ownerId: string) => {
      const profile = get();
      const authenticatedProfile = produce(profile, (draftState) => {
        draftState.authenticated = true;
        draftState.authorId = authorId;
        draftState.ownerId = ownerId;
        draftState.status = "authenticated";
      });
      set(authenticatedProfile);
      onAuthenticated(authorId, ownerId);
      const { theme } = await SettingsService.ArtistSettings(ownerId);
      if (theme === "dark") {
        set({ darkMode: true });
      } else {
        set({ darkMode: false });
      }
    };

    const logOut = () => {
      const profile = get();
      const id = get().authorId || "";
      const loggedOutProfile = produce(profile, (draftState) => {
        draftState.authenticated = false;
        draftState.authorId = "";
        draftState.ownerId = "";
        draftState.status = "unauthenticated";
      });
      set(loggedOutProfile);
      onUnAuthed(id);
    };

    LoginService.watchAuthState(authenticate, logOut);
  },
}));
