export default class ObserverService {
    private static instance: ObserverService;
    private storage: Map<String, Function>;

    private constructor() {
        this.storage = new Map<String, Function>();
    }

    public static please(): ObserverService {
        if (!ObserverService.instance) {
            ObserverService.instance = new ObserverService();
        }

        return ObserverService.instance;
    }

    public add(id: string, fn: Function) {
        this.storage.set(id, fn);
    }
    public exist(id: string): boolean {
        //console.log(this.storage);
        return this.storage.has(id);
    }
    public remove(id: string) {
        let stop = this.storage.get(id);
        stop && stop();
    }

}
