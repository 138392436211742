import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { alertOutline, closeOutline, exitOutline } from "ionicons/icons";
import React from "react";
import { useConversations } from "../data/store/useConversations";
import { useProfile } from "../data/store/useProfile";


interface deleteConversationId {
    id: string
    onComplete: () => void
}

const ConversationDeleteModal: React.FC<deleteConversationId> = ({ id, onComplete }) => {
    const convo = useConversations(x => x.retrieve(id));
    const leave = useConversations(x => x.leave);
    const { ownerId, authorId } = useProfile();
    return <IonModal isOpen={id !== ""} onDidDismiss={() => onComplete()}>
        {convo && <IonList>
            <IonToolbar color="secondary">
                <IonButtons>
                    <IonTitle color="dark">
                        {convo.title}
                    </IonTitle>
                </IonButtons>
                <IonButtons slot="end">
                    <IonButton color="dark" onClick={() => {
                        onComplete();
                    }}>
                        <IonIcon icon={closeOutline} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonItem routerLink={'/issue/conversation/' + id} onClick={() => onComplete()}>
                <IonIcon slot='start' icon={alertOutline} />
                <IonLabel>
                    Report Conversation
                </IonLabel>
            </IonItem>
            <IonItem routerLink="/conversations?thread=start" onClick={() => {
                ownerId && authorId && leave(ownerId, authorId, convo.id)
                onComplete();
            }}>
                <IonIcon slot='start' icon={exitOutline} />
                <IonLabel>
                    Leave Conversation (you won't be able to see it anymore)
                </IonLabel>
            </IonItem>
        </IonList>
        }
    </IonModal >
}

export default ConversationDeleteModal;