import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React, { useEffect } from "react";
import Content from "../components/Content";
import SongList from "../components/SongList";
import { useSongs } from "../data/store/useSongs";
import { usePageTitle } from "../util/sugar";

const SongsPage: React.FC = () => {
  const { observe } = useSongs();
  const fetchSome = useSongs((x) => x.fetchSome);
  const status = useSongs((x) => x.status);
  const songs = useSongs((x) => x.timeline);

  usePageTitle("Songs");

  useEffect(() => {
    fetchSome();
    observe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content className="no-scroll">
      <IonLoading isOpen={status === "initial" || status === "fetching"} />
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <SongList status={status} fetchMore={fetchSome} songRefs={songs} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default SongsPage;
