import { firestore } from "firebase/app";
import { Playlist } from "../models/Playlists";

export class PlaylistService {
  static StronglyTypePlaylistData = (
    playlistDoc:
      | firestore.QueryDocumentSnapshot<firestore.DocumentData>
      | firestore.DocumentSnapshot<firestore.DocumentData>
  ) => {
    return { ...playlistDoc.data(), id: playlistDoc.id } as Playlist;
  };

  static transmit = (freshPlaylist: Playlist) => {
    const db = firestore();
    return db.collection("playlist").doc(freshPlaylist.id).set(freshPlaylist);
  };

  static fetchAll = () => {
    return new Promise<Playlist[]>((resolve, reject) => {
      const db = firestore();
      db.collection("playlist")
        .get()
        .then((playlistCollection) => {
          const allPlaylists = playlistCollection.docs.map((playlistDoc) => {
            return PlaylistService.StronglyTypePlaylistData(playlistDoc);
          });
          resolve(allPlaylists);
        })
        .catch(reject);
    });
  };

  static fetch = (id: string) => {
    return new Promise<Playlist>((resolve, reject) => {
      const db = firestore();
      db.collection("playlist")
        .doc(id)
        .get()
        .then((playlistDoc) => {
          const playlist =
            PlaylistService.StronglyTypePlaylistData(playlistDoc);
          resolve(playlist);
        })
        .catch(reject);
    });
  };

  static delete = (id: string): Promise<void> => {
    return new Promise<void>((resolve) => {
      const db = firestore();
      if (!id || id === "") {
        return;
      }
      db.collection("playlist")
        .doc(id)
        .delete()
        .then(() => {
          resolve();
        });
    });
  };

  static update = (playlist: Playlist): Promise<Playlist> => {
    return new Promise<Playlist>((resolve, reject) => {
      const db = firestore();
      const serverPlayList = db.collection("playlist").doc(playlist.id);
      serverPlayList.set(playlist, { merge: true }).then(() => {
        serverPlayList
          .get()
          .then((playlistDoc) => {
            const playlist =
              PlaylistService.StronglyTypePlaylistData(playlistDoc);
            resolve(playlist);
          })
          .catch(reject);
      });
    });
  };
}
