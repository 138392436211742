import firebase from "firebase/app";
import HostConfig from "../HostConfig";
import { ArtistRef } from "../models/Directory";
import { OwnerId } from "../models/User";
import { ArtistService } from "./ArtistService";
import P2PAudioService from "./P2PAudioService";

interface ConcertParticipantInfo extends ParticipantInfo {
    artist: ArtistRef
    position: [number, number, number]
    status: "mc" | "audience" | "performing" | "next" | "lobby" | "initial"
}
export interface ParticipantInfo {
    artist: ArtistRef
    position: [number, number, number]
    status: "lobby" | string
}


export type SpaceMembers = Record<OwnerId, ParticipantInfo>;



export class SpaceService {
    /**
     * 
     * @param spaceId id 
     * @param ownerId use joining
     */
    static async join(spaceId: string, ownerId: string, status: string = "initial", onUpdate: (info: SpaceMembers) => void = () => { }) {
        return new Promise<SpaceMembers>(async (resolve, reject) => {
            const members: SpaceMembers = {};

            const { alias, id, avatar } = await ArtistService.fetchByOwnerId(ownerId) || {};
            let artist = { alias, id, avatar } as ArtistRef;
            if (alias === undefined) {
                return reject(new Error("no alias found"));
            }
            if (avatar === undefined) {
                artist.avatar = HostConfig.icon;
            }
            const position: [number, number, number] = [0, 0, 0];
            members[ownerId] = { artist, status, position }
            const spaceRef = firebase.firestore()
                .collection("spaces")
                .doc(spaceId);

            spaceRef.set({ members }, { merge: true }).then(() => {
                spaceRef.get().then(spaceDoc => {
                    const { members } = spaceDoc.data() as { members: SpaceMembers };
                    members ? resolve(members) : reject(members);
                    spaceRef.onSnapshot(() => {
                        const { members } = spaceDoc.data() as { members: SpaceMembers };
                        members && onUpdate(members)
                    })
                }).catch(reject)
            }).catch(reject);
        });
    }
    static leave(spaceId: string, ownerId: string) {
        return firebase.firestore()
            .collection("spaces")
            .doc(spaceId)
            .collection("members")
            .doc(ownerId).delete();
    }
    static transition(fromSpaceId: string, toSpaceId: string, ownerId: string) {
        return SpaceService.leave(fromSpaceId, ownerId).then(() => {
            SpaceService.join(toSpaceId, ownerId);
        })
    }
    static initializeAudioP2P(spaceId: string, ownerId: string) {
        P2PAudioService.please().joinOrCreate(spaceId, ownerId);
    }


}