import React from 'react';
import { IonChip } from '@ionic/react';
import { ArtistSearchResult } from '../data/store/useSearch';


interface ArtistItemProps {
    artist: ArtistSearchResult;
    onClick: any;
}

const ArtistSearchResultButton: React.FC<ArtistItemProps> = ({ onClick, artist }) => {
    const { alias } = artist;
    return <IonChip onClick={onClick}>{alias}</IonChip>
};

export default ArtistSearchResultButton;