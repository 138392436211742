import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonGrid, IonInput, IonItem, IonItemDivider, IonList, IonLoading, IonRow, IonTextarea, IonToggle } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import Content from '../components/Content';
import Uploader from '../components/Uploader';
import { useArtists } from '../data/store/useArtists';
import { useProfile } from '../data/store/useProfile';
import { useRoles } from '../data/store/useRoles';
import { useTitle } from '../data/store/useTitle';
import { sanitize, useCssImage, usePageTitle } from '../util/sugar';


interface AccountProps extends RouteComponentProps { };


const Account: React.FC<AccountProps> = () => {
  usePageTitle("Account");
  const { changeProfile } = useProfile();
  const { role } = useRoles();
  const { fetchMeta, shouldFetch } = useArtists();
  const authorId = useProfile(x => x.authorId) || "";
  const status = useProfile(x => x.status) || "";
  const [showAliasModifier, setShowAliasModifier] = useState(false);
  const [showWallModifier, setShowWallModifier] = useState(false);
  const [showAvatarModifier, setShowAvatarModifier] = useState(false);
  const [showWebsiteModifier, setShowWebsiteModifier] = useState(false);
  const [showPrivacyModifier, setShowPrivacyModifier] = useState(false);
  const [showStatementModifier, setShowStatementModifier] = useState(false);

  const artist = useArtists(x => x.retrieve(authorId))

  const setTitle = useTitle(x => x.setTitle);

  useEffect(() => {
    setTitle("account");
  }, [authorId, setTitle]);


  useEffect(() => {
    if (status === 'unauthenticated') {
      window.location.href = "https://songadayforamonth.com/login";
    }
    if (authorId && !artist) {
      shouldFetch(authorId) && fetchMeta(authorId);
    } else if (authorId && artist) {
      const { statement, website, wall, avatar, alias, privacy } = { ...artist };
      statement && setStatement(statement);
      website && setWebsite(website);
      alias && setAlias(alias);
      wall && setWallpaper(wall);
      avatar && setAvatar(avatar);
      console.log(privacy);
      privacy && setPrivacy(privacy);

    }
  }, [artist, authorId, fetchMeta, shouldFetch, status]);


  const [freshPrivacy, setPrivacy] = useState("");
  const [freshStatement, setStatement] = useState("");
  const [freshWebsite, setWebsite] = useState("");
  const [freshAlias, setAlias] = useState("");
  const [freshAvatar, setAvatar] = useState("");
  const [freshWallpaper, setWallpaper] = useState("");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const artistBg = artist && useCssImage(freshWallpaper)

  return (<Content id='main'>
    <IonGrid style={{ "--ion-background-color": "clear!important", height: "100%", background: artistBg && artistBg }}>
      <IonLoading isOpen={status === 'unknown'}></IonLoading>
      <IonRow>
        <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">

          <IonCard className="ion-padding-top ion-text-center">
            <IonCardHeader >
              {artist && <IonAvatar style={{ width: 108, height: 108, margin: 'auto' }}>
                <img src={artist && freshAvatar} alt={artist && freshAlias} />
              </IonAvatar>}
            </IonCardHeader>
            <IonCardContent>
              <h2>{freshAlias}</h2>
              <p>{freshStatement}</p>
              <a href={sanitize(freshWebsite)}>
                {freshWebsite}
              </a>
              {authorId && <IonList inset>
                <IonItem onClick={() => setShowAliasModifier(x => !x)}><IonChip>{showAliasModifier ? "Your Alias" : "Change Alias"}</IonChip></IonItem>
                {showAliasModifier && <IonItem>
                  <IonInput value={freshAlias} onIonChange={(e) => { setAlias(e.detail.value!) }} />
                  <IonButtons slot='end'>                   <IonButton fill='outline' onClick={() => {
                    freshAlias && changeProfile("alias", freshAlias);
                    setShowAliasModifier(x => !x);
                  }
                  }>
                    Save
                  </IonButton>
                  </IonButtons>
                </IonItem>}
                <IonItem onClick={() => setShowStatementModifier(x => !x)}><IonChip>{showStatementModifier ? "Your Statement" : "Change Statement"}</IonChip></IonItem>
                {showStatementModifier && <IonItem>
                  <IonTextarea value={freshStatement} onIonChange={(e) => { setStatement(e.detail.value!) }} />
                  <IonButtons slot='end'>                   <IonButton fill='outline' onClick={() => {
                    freshStatement && changeProfile("statement", freshStatement);
                    setShowStatementModifier(x => !x);
                  }
                  }>
                    Save
                  </IonButton>
                  </IonButtons>

                </IonItem>}
                <IonItem onClick={() => setShowWebsiteModifier(x => !x)}><IonChip>{showWebsiteModifier ? "Your Website" : "Change Website"}</IonChip>
                </IonItem>
                {showWebsiteModifier && <IonItem>
                  <IonTextarea value={freshWebsite} onIonChange={(e) => { setWebsite(e.detail.value!) }} />
                  <IonButtons slot='end'>                   <IonButton fill='outline' onClick={() => {
                    freshWebsite && changeProfile("website", freshWebsite);
                    setShowWebsiteModifier(x => !x);
                  }
                  }>
                    Save
                  </IonButton>
                  </IonButtons>
                </IonItem>}

                <IonItem onClick={() => setShowAvatarModifier(x => !x)}><IonChip>{showAvatarModifier ? "Changing Avatar" : "Change Avatar"}</IonChip></IonItem>
                {showAvatarModifier && <IonItem>
                  <Uploader message="select your avatar" collection='avatar' onComplete={(media) => { changeProfile("avatar", media.src);; setShowAvatarModifier(x => !x); setAvatar(media.src) }} />
                </IonItem>}
                <IonItem onClick={() => setShowWallModifier(x => !x)}><IonChip>{showWallModifier ? "Changing Wallpaper" : "Change Wallpaper"}</IonChip></IonItem>
                {showWallModifier && <IonItem>
                  <Uploader message="select your wallpaper" collection='wall' onComplete={(media) => { changeProfile("wall", media.src); setShowWallModifier(x => !x); setWallpaper(media.src) }} />
                </IonItem>}
                <IonItem onClick={() => setShowPrivacyModifier(x => !x)}><IonChip>{showPrivacyModifier ? "Your Privacy" : "Change Privacy"}</IonChip></IonItem>
                {showPrivacyModifier && <IonItem>
                  <IonButtons slot='start'>
                    <IonToggle checked={freshPrivacy === "member"} onIonChange={(e) => { setPrivacy(e.detail.checked ? "member" : "public"); changeProfile("privacy", e.detail.checked ? "member" : "public") }} />
                  </IonButtons>
                  <IonButtons slot='end'>
                    {freshPrivacy === "member" ? "Members Only" : "Public"}
                  </IonButtons>
                </IonItem>}
                <IonItemDivider color='clear' />
                <IonItem routerLink="/transmit" routerDirection="forward">Transmit</IonItem>
                {role && role.authority && role.authority.includes("admin") && <IonItem routerLink='/admin'>
                  Admin
            </IonItem>}

                <IonItem routerLink={"/artist/" + authorId} routerDirection="forward">View Artist Page</IonItem>
                <IonItem routerLink="/logout" routerDirection="none">Logout</IonItem>
              </IonList>}
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  </Content >)
};

export default Account;