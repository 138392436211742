import produce from "immer";
import create from "zustand";
import { Artist, ArtistId } from "../../models/Artist";
import { ArtistRef } from "../../models/Directory";
import { ArtistService } from "../../services/ArtistService";
import { DirectoryService } from "../../services/DirectoryService";

export type ArtistDirectory = {
  cursor: number;
  pageSize: number;
  artists: Record<ArtistId, Artist | ArtistRef>;
  queued: ArtistId[];
  fetching: ArtistId[];
  fetchingMeta: ArtistId[];
  empty: ArtistId[];
  meta: ArtistId[];
  all: ArtistId[];
  roster: ArtistId[];
  observing: boolean;
  status: "idle" | "fetching" | "initial";
  fetch: (id: string) => void;
  fetchMeta: (id: string) => void;
  shouldFetch: (id: string) => boolean;
  shouldFetchMeta: (id: string) => boolean;
  fetchAll: () => void;
  retrieve: (id: ArtistId) => ArtistRef | undefined;
  observeDirectory: () => void;
};

export const useArtists = create<ArtistDirectory>((set, get) => ({
  observing: false,
  artists: {},
  fetching: [],
  roster: [],
  fetchingMeta: [],
  meta: [],
  status: "initial",
  empty: [],
  queued: [],
  all: [],
  cursor: 1,
  pageSize: 10,
  observeDirectory: () => {
    set({ observing: true, cursor: get().cursor + 2 });
    DirectoryService.observe(get().pageSize * get().cursor, (allArtists) => {
      let artists: Record<string, ArtistRef> = {};
      for (const artist of allArtists) {
        artists[artist.id] = artist;
      }
      const roster = allArtists.map((x) => x.id);
      const all = [...roster];
      const status = "idle";
      set({ roster, artists, all, status });
    });
  },
  retrieve: (id) => {
    const directory = get();
    const artist = directory.artists[id];

    return artist;
  },
  fetchAll: async () => {
    set({ status: "fetching" });
    const artists = await DirectoryService.fetchAll();
    const directoryWithEntryAdded = produce(get(), (draftState) => {
      for (const artist of artists) {
        if (!draftState.roster.includes(artist.id)) {
          draftState.roster.push(artist.id);
        }
        if (!draftState.all.includes(artist.id)) {
          draftState.all.push(artist.id);
        }
        draftState.artists[artist.id] = {
          ...artist,
          ...draftState.artists[artist.id],
        };
      }
    });
    set(directoryWithEntryAdded);
    set({ status: "idle" });
  },
  shouldFetch: (id) => {
    const directory = get();
    if (
      directory.all.includes(id) ||
      directory.fetching.includes(id) ||
      directory.queued.includes(id) ||
      directory.empty.includes(id)
    )
      return false;
    return true;
  },
  shouldFetchMeta: (id) => {
    const directory = get();
    if (
      directory.meta.includes(id) ||
      directory.fetchingMeta.includes(id) ||
      directory.queued.includes(id) ||
      directory.empty.includes(id)
    )
      return false;
    return true;
  },
  queue: (id: ArtistId) => {
    const directory = get();
    const directoryWithEntryInQueue = produce(directory, (draftState) => {
      draftState.queued.push(id);
    });
    set(directoryWithEntryInQueue);
  },
  queueMany: (ids: ArtistId[]) => {
    const directory = get();
    const directoryWithEntriesInQueue = produce(directory, (draftState) => {
      for (const artistId of ids) draftState.queued.push(artistId);
    });
    set(directoryWithEntriesInQueue);
  },
  fetch: async (id) => {
    if (!id) {
      return 0;
    }
    const directory = get();
    const directoryWithEntryInFetching = produce(directory, (draftState) => {
      draftState.fetching.push(id);
    });
    set(directoryWithEntryInFetching);
    //Initiate the Fetch for the nextQueue
    const artistRef = await DirectoryService.fetch(id);
    //Add Fetched Entries to directory
    const nextState = produce(get(), (draftState) => {
      draftState.artists[artistRef.id] = artistRef;
      draftState.fetching = draftState.fetching.filter(
        (x) => x !== artistRef.id
      );
      if (!draftState.all.includes(id)) draftState.all.push(id);
    });
    set(nextState);
  },
  fetchMeta: async (id) => {
    const directory = get();
    const directoryWithEntryInFetching = produce(directory, (draftState) => {
      draftState.fetching.push(id);
    });
    set(directoryWithEntryInFetching);
    try {
      //Initiate the Fetch for the nextQueue
      const artistRef = await ArtistService.fetch(id);
      //Add Fetched Entries to directory
      const nextState = produce(get(), (draftState) => {
        draftState.artists[artistRef.id] = artistRef;
        draftState.fetching = draftState.fetching.filter(
          (x) => x !== artistRef.id
        );
        if (!draftState.all.includes(id)) draftState.all.push(id);
      });
      set(nextState);
    } catch {
      const emptyState = produce(get(), (draftState) => {
        draftState.fetching = draftState.fetching.filter((x) => x !== id);
        if (!draftState.empty.includes(id)) draftState.empty.push(id);
      });
      set(emptyState);
    }
  },
}));
