import { IonButton, IonButtons, IonInfiniteScrollContent, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import AsyncArtistButton from '../components/AsyncArtistButton';
import Content from '../components/Content';
import { useNotifications } from '../data/store/useNotifications';
import { Notice } from '../models/Notice';
import { usePageTitle } from '../util/sugar';


const Activity: React.FC = () => {
    usePageTitle("Notifications");
    const { remove, clear, notifications } = useNotifications();
    return (
        <Content>
            <IonItem>
                <IonButtons slot='end'>
                    {notifications.length !== 0 && <IonButton fill='solid' color={"tertiary"} onClick={() => { clear(notifications[0].to) }} >
                        Clear Notifications</IonButton>}
                </IonButtons>
            </IonItem>

            {notifications.length !== 0 && <IonInfiniteScrollContent>
                {notifications.map((notice: Notice, index: number) => {
                    return <IonItem detail key={index} onClick={() => { remove(notice.id); }} routerLink={notice.link}>
                        <AsyncArtistButton id={notice.from} />

                        <IonLabel>
                            {notice.text}
                        </IonLabel>
                    </IonItem>
                })}
            </IonInfiniteScrollContent>}
            {notifications.length === 0 && <IonItem><IonLabel children={"No Notifications remaining!"} /></IonItem>}
        </Content>
    );
};

export default React.memo(Activity);