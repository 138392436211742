import { Identifiable } from "./Identifiable";


export const defaultConfiguration: Configuration = {
    supportEmail: "songadayforamonth@gmail.com",
    allowPublic: "yes", title: "Song a Day for a Month", id: "public",
    rules: "one fresh song per day",
    disclaimer: "artists own their songs",
    mission: "Woody Guthrie and Bob Marley woke up early and wrote songs.We challenge you to do the same, every day."
}

export interface Configuration extends Identifiable {
    mission: string,
    title: string;
    supportEmail: string;
    rules: string;
    transmitNotice?: string;
    disclaimer: string;
    allowPublic?: string;
}