import {
  IonButton,
  IonCol,
  IonIcon,
  IonList,
  IonProgressBar,
  IonRow,
} from "@ionic/react";
import { arrowDownOutline, playOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useVirtual } from "react-virtual";
import { usePlayer } from "../data/store/usePlayer";
import { usePlaylist } from "../data/store/usePlaylist";
import { songHookStatus, useSongs } from "../data/store/useSongs";
import { SongRef } from "../models/Song";
import MediaService from "../services/MediaService";
import CompactSongItem from "./CompactSongItem";

interface OwnProps {
  songRefs: SongRef[];
  fetchMore: () => void;
  status: songHookStatus;
  compact?: boolean;
  nextPageText?: string;
}

interface SongListProps extends OwnProps {}

const CompactSongList: React.FC<SongListProps> = ({
  songRefs,
  status,
  fetchMore,
  nextPageText,
}) => {
  const parentRef = React.useRef<HTMLIonListElement>(null);
  const { queueSong, setNowPlaying } = usePlaylist();
  const isPaused = usePlayer((x) => x.isPaused);
  const retrieve = useSongs((x) => x.retrieve);
  const [limit, setLimit] = useState<number>(20);
  const rowVirtualizer = useVirtual({
    size: limit > songRefs.length ? songRefs.length : limit,
    parentRef,
  });

  const playAll = () => {
    if (isPaused) {
      const first = retrieve(songRefs[0].id);
      first &&
        MediaService.please()
          .setSong(first)
          .then(() => {
            first && setNowPlaying(first.id);
            MediaService.please().play();
          });
    }
    songRefs.forEach((x) => queueSong(x.id));
  };

  return (
    <>
      <IonList ref={parentRef} style={{ background: "clear" }} color={"light"}>
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          const songRef = songRefs[virtualRow.index];
          return <CompactSongItem key={songRef.id} id={songRef.id} />;
        })}
      </IonList>
      {songRefs.length !== 0 && (
        <IonRow>
          <IonCol size="6">
            <IonButton
              fill="outline"
              color="favorite"
              expand="full"
              onClick={playAll}
            >
              Play All
              <IonIcon icon={playOutline} />
            </IonButton>
          </IonCol>
          <IonCol size="6">
            {status === "idle" ? (
              <IonButton
                fill="outline"
                color="tertiary"
                expand="full"
                onClick={() => {
                  songRefs.length > limit
                    ? setLimit((x) => x + 20)
                    : fetchMore();
                }}
              >
                {songRefs.length < limit ? nextPageText : "Load More"}
                <IonIcon icon={arrowDownOutline} />
              </IonButton>
            ) : (
              <IonProgressBar
                style={{ height: 52 }}
                type="indeterminate"
                color="tertiary"
              />
            )}
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default CompactSongList;
