import { IonApp, IonPage, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ContextModals from "./components/ContextModals";
import Favorites from "./components/Favorites";
import Menu from "./components/Menu";
import NowPlayingBottomBar from "./components/NowPlayingBottomBar";
import Playlist from "./components/Playlist";
import { SongDelete } from "./components/SongDelete";
import { CreatePlaylist } from "./components/CreatePlaylist";
import SongEditForm from "./components/SongEditForm";
import TopToolbar from "./components/TopToolbar";
import TransmitForm from "./components/TransmitForm";
import { useArtists } from "./data/store/useArtists";
import { useConfig } from "./data/store/useConfig";
import { useFavorites } from "./data/store/useFavorites";
import { useNotifications } from "./data/store/useNotifications";
import { useProfile } from "./data/store/useProfile";
import { useRoles } from "./data/store/useRoles";
import Account from "./pages/Account";
import Activity from "./pages/Activity";
import Admin from "./pages/Admin";
import ArtistDetail from "./pages/ArtistDetail";
import ArtistList from "./pages/ArtistList";
import Concert from "./pages/Concert";
import Conversations from "./pages/Conversations";
import Issue from "./pages/Issue";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Mission from "./pages/Mission";
import PlaylistDetail from "./pages/PlaylistDetail";
import Playlists from "./pages/Playlists";
import ReviseSong from "./pages/ReviseSong";
import SearchPage from "./pages/SearchPage";
import SongDetail from "./pages/SongDetail";
import SongRevisions from "./pages/SongRevisions";
import SongsPage from "./pages/SongsPage";
import TagsPage from "./pages/TagsPage";
import Albums from "./pages/Albums";
import Album from "./pages/Album";
import CreateAlbum from "./pages/CreateAlbum";
import Visualizer from "./pages/Visualizer";
/* Theme variables */
import "./theme/variables.css";
import { useBodyClass } from "./util/sugar";
//import { MigrationService } from './services/MigrationService';

// //MigrationService.please().searchArtists();
// //MigrationService.please().songs();
// //MigrationService.please().updateSongCounts()
// Sentry.init({
//   dsn: "https://c8f4de5faf82447392bf4f8c362d3d47@o224096.ingest.sentry.io/5468581",
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });
//MigrationService.please().restrict("f7605f0a4da");

const SadFamApp: React.FC = () => {
  const { darkMode, observeAuthentication } = useProfile();
  const { observeDirectory, fetch } = useArtists();
  const observeConfig = useConfig((x) => x.observe);
  const observeRoles = useRoles((r) => r.observe);
  const initializeFavorites = useFavorites((r) => r.initializeFavorites);

  const { observe, clear } = useNotifications();

  useBodyClass(darkMode ? "dark" : "light");
  useEffect(() => {
    observeDirectory();
    observeAuthentication((artistId, ownerID) => {
      observeRoles(ownerID);
      observeConfig(ownerID);
      initializeFavorites(ownerID);
      observe(artistId);
      fetch(artistId);
    }, clear);
  }, [
    clear,
    fetch,
    initializeFavorites,
    observe,
    observeAuthentication,
    observeConfig,
    observeDirectory,
    observeRoles,
  ]);

  return (
    <IonApp className={`${darkMode ? "dark-theme" : ""}`}>
      <IonReactRouter>
        <Menu />
        <Playlist />
        <ContextModals />
        <IonPage>
          <TopToolbar />
          <IonRouterOutlet animated={false} id="main">
            <Switch>
              <Route path="/songs" component={SongsPage} exact={true} />
              <Route path="/artists" component={ArtistList} exact={true} />
              <Route
                path={["/artist/:id", "/artists/:id"]}
                component={ArtistDetail}
                exact={true}
              />
              <Route
                path={["/song/:id", "/songs/:id"]}
                component={SongDetail}
              />
              <Route path={"/delete-song/:id"} component={SongDelete} />
              <Route path={"/edit-song/:id"} component={SongEditForm} />
              <Route path="/revise-song/:id" component={ReviseSong} />
              <Route path="/song-revisions/:id" component={SongRevisions} />
              <Route path="/transmit" component={TransmitForm} exact={true} />
              <Route
                path="/conversations"
                component={Conversations}
                exact={true}
              />
              <Route path="/notices" component={Activity} exact={true} />
              <Route path="/account" component={Account} />
              <Route path="/login" component={Login} />
              <Route path="/issue/:collection/:id" component={Issue} />
              <Route path="/admin" component={Admin} />
              <Route path="/search" component={SearchPage} />
              <Route path="/visualizer" component={Visualizer} />
              <Route path="/albums" component={Albums} />
              <Route path="/album/:id" component={Album} />
              <Route path="/album-create" component={CreateAlbum} />
              <Route path="/playlists" component={Playlists} />
              <Route path="/playlist/:id" component={PlaylistDetail} />
              <Route path="/playlist-create" component={CreatePlaylist} />
              <Route path="/mission" component={Mission} />
              <Route path="/favorites" component={Favorites} />
              <Route path="/logout" component={Logout} />
              <Route path="/concert" component={Concert} />
              <Route path="/tag/:id" component={TagsPage} />
              <Redirect to="/songs" />
            </Switch>
          </IonRouterOutlet>
          <NowPlayingBottomBar />
        </IonPage>
      </IonReactRouter>
    </IonApp>
  );
};

export default SadFamApp;
