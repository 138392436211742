import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  IonButton,
  IonButtons,
  IonText,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonTextarea,
} from "@ionic/react";
import { paperPlane } from "ionicons/icons";
import { useConversations } from "../data/store/useConversations";
import { useProfile } from "../data/store/useProfile";

import { ArtistId } from "../models/Artist";
import { usePageTitle } from "../util/sugar";
import ArtistSelector from "./ArtistSelector";
import AsyncArtistButton from "./AsyncArtistButton";

interface ConversationStarterProps {}

const ConversationStarter: FC<ConversationStarterProps> = (props) => {
  const authorId = useProfile((x) => x.authorId);
  const ownerId = useProfile((x) => x.ownerId);
  const [members, setMembers] = useState<ArtistId[]>([]);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const { startConversation, activeConversation } = useConversations();
  const history = useHistory();

  usePageTitle("Conversation Starter");

  useEffect(() => {
    if (activeConversation && activeConversation !== "start") {
      history.push({ search: "?thread=" + activeConversation });
    }
  }, [activeConversation, history]);

  const transmitConversation = () => {
    const titleWithFallbackText = title || text;
    authorId &&
      ownerId &&
      startConversation(titleWithFallbackText, members, {
        authorId,
        text,
        ownerId,
      });
    setText("");
    setTitle("");
  };

  useEffect(() => {
    if (authorId && !members.includes(authorId)) {
      setMembers(() => [authorId]);
    }
  }, [authorId, members]);

  return (
    <IonContent id="starter">
      <IonItem></IonItem>
      <IonList>
        {members.length !== 0 && (
          <ArtistSelector
            setMembers={setMembers}
            placeholder={"Participants"}
            members={members}
          />
        )}
      </IonList>

      <IonList>
        <IonItem>
          <IonLabel position="floating" color="primary">
            Subject
          </IonLabel>
          <IonInput
            name="username"
            type="text"
            value={title}
            spellCheck={false}
            autocapitalize="off"
            onIonChange={(e) => setTitle(e.detail.value!)}
            required
          />
        </IonItem>
        <IonItem color="light">
          <IonButtons slot="start">
            {authorId && (
              <AsyncArtistButton
                disabled={true}
                variant="avatar"
                id={authorId}
              />
            )}
          </IonButtons>

          <IonTextarea
            className="message-textarea"
            inputmode="text"
            autofocus={true}
            autoGrow
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                text &&
                  authorId &&
                  members.length > 1 &&
                  transmitConversation();
              }
            }}
            onIonChange={(e) => setText(e.detail.value!)}
            value={text}
            placeholder="message..."
          />
        </IonItem>

        {text && title && authorId && members.length > 1 && (
          <IonButton
            fill="outline"
            color={
              text && authorId && members.length > 1 ? "favorite" : "primary"
            }
            disabled={!text || !authorId || members.length === 1}
            onClick={() => {
              transmitConversation();
            }}
            expand="block"
          >
            Begin Conversation <IonIcon icon={paperPlane} />
          </IonButton>
        )}

        {members.length === 1 && text && title ? (
          <IonText class="ion-padding-horizontal" color="danger">
            Add some Participants to start the conversation
          </IonText>
        ) : !title || !text ? (
          <IonText class="ion-padding-horizontal" color="danger">
            Add subject and message to start the conversation
          </IonText>
        ) : (
          ""
        )}
      </IonList>
    </IonContent>
  );
};

export default ConversationStarter;
