import { IonButtons, IonCol, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { musicalNote } from "ionicons/icons";
import React from "react";
import { useArtists } from "../data/store/useArtists";
import { ArtistId } from "../models/Artist";
import ArtistButton from "./ArtistButton";

interface ArtistItemProps {
  id: ArtistId;
}

const ArtistItem: React.FC<ArtistItemProps> = ({ id }) => {
  const artist = useArtists((x) => x.retrieve(id));

  return (
    <IonItem
      onClick={() => {}}
      className="inner-chill"
      detail={true}
      routerLink={`/artist/${id}`}
    >
      <IonButtons slot="start">
        {artist && <ArtistButton variant="avatar" artist={artist} />}
      </IonButtons>
      <IonButtons>
        <IonCol>
          <IonLabel className="ion-text-center">
            {artist && artist.alias}
          </IonLabel>
        </IonCol>
      </IonButtons>
      <IonButtons slot="end">
        {artist && artist.songs}
        <IonIcon icon={musicalNote} />
      </IonButtons>
    </IonItem>
  );
};

export default ArtistItem;
