import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToggle,
} from "@ionic/react";
import { useContextModals } from "../data/store/useContextModals";
import { usePlaylist } from "../data/store/usePlaylist";
import { usePlaylists } from "../data/store/usePlaylists";
import { useProfile } from "../data/store/useProfile";
import Content from "./Content";
import { usePageTitle } from "../util/sugar";
import { MediaReference } from "../models/Song";
import Uploader from "./Uploader";

export const CreatePlaylist: React.FC = () => {
  const { push } = useHistory();
  const { songs } = usePlaylist();
  const { transmit, status } = usePlaylists();
  const { authorId, ownerId } = useProfile();
  const contextSong = useContextModals((x) => x.modalSong);
  const [name, setName] = useState("");
  const [art, setArt] = useState<MediaReference>({} as MediaReference);
  const [showArt, setShowArt] = useState(false);
  const [isPlaylistVisible, setIsPlaylistVisible] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const isUploadedArt = showArt ? !art.src : false;
  const isBtnDisabled =
    !ownerId || !authorId || isUploadedArt || name === "" || isBtnDisable;

  usePageTitle("Create playlist");

  const toggleShowArt = () => {
    setShowArt((prevState) => !prevState);
    showArt && setArt({} as MediaReference);
  };

  const savePlayList = () => {
    const songsList = !!contextSong.id ? [contextSong.id] : songs;
    setIsBtnDisable(true);

    transmit(ownerId!, name, art, isPlaylistVisible, songsList, authorId!).then(
      (id) => {
        push(`/playlist/${id}`);
      }
    );
  };

  return (
    <Content>
      <IonRow>
        <IonCol sizeMd="12" sizeLg="8" offsetMd="2" offsetLg="2">
          <IonCard>
            <IonCardContent>
              <IonItem>
                <IonLabel position="stacked" color="primary">
                  Title
                </IonLabel>
                <IonInput
                  spellCheck={false}
                  autocapitalize="off"
                  autofocus={true}
                  placeholder="Playlist title"
                  onIonChange={(e) => setName(e.detail.value!)}
                  required
                />
              </IonItem>

              <IonItem lines="none">
                <IonLabel color="primary">Private playlist</IonLabel>
                <IonToggle
                  checked={isPlaylistVisible}
                  onClick={() =>
                    setIsPlaylistVisible((prevState) => !prevState)
                  }
                />
              </IonItem>

              <IonItem>
                <IonLabel color="primary">Upload playlist art</IonLabel>
                <IonToggle checked={showArt} onClick={toggleShowArt} />
              </IonItem>

              {showArt && (
                <Uploader
                  message="select an image file"
                  collection={"covers"}
                  onComplete={(media) => {
                    if (media) {
                      setArt(media);
                    }
                  }}
                />
              )}
              <IonButton
                onClick={savePlayList}
                disabled={isBtnDisabled}
                style={{ margin: 10 }}
              >
                Save
              </IonButton>

              <IonButton
                onClick={() => push("/playlists")}
                slot="end"
                color="danger"
                style={{ margin: 10 }}
              >
                Cancel
              </IonButton>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>

      <IonLoading isOpen={status === "fetching"} />
    </Content>
  );
};
