import { firestore } from "firebase";
import create from "zustand";
import { ArtistId } from "../../models/Artist";
import { ArtistRef } from "../../models/Directory";
import { FreshSong, SongId, MediaReference } from "../../models/Song";
import { OwnerId } from "../../models/User";
import { SongService } from "../../services/SongService";
import { TransmissionService } from "../../services/TransmissionService";
import isEmpty from "lodash.isempty";

export type Transmission = {
  title: string;
  description: string;
  media: MediaReference;
  collection: "songs" | "avatars" | "wall" | "message" | "art";
  authors: ArtistId[];
  recentUpload?: SongId;
};

export type TransmissionState = {
  todaysTransmission?: SongId | null;
  title: string;
  description: string;
  media?: MediaReference;
  art?: MediaReference;
  status: "fetching" | "transmitting" | "idle";
  collection?: "songs" | "avatars" | "wall" | "message" | "art";
  authors?: ArtistId[];
  artists?: Record<ArtistId, ArtistRef>;
  isValid: () => boolean;
  fetchTodaysTransmission: (id: OwnerId) => void;
  setAuthors: (ids: ArtistId[]) => void;
  setMedia: (media: MediaReference) => void;
  setArt: (art: MediaReference) => void;
  setDescription: (description: string) => void;
  setTitle: (title: string) => void;
  transmit: (ownerId: string) => void;
  revoke: (id: SongId) => void;
  aggregate: (ownerId: string) => FreshSong;
  clearTodayTransmission: () => void;
};
export const useTransmission = create<TransmissionState>((set, get) => ({
  status: "idle",
  title: " ",
  description: " ",
  revoke: async (id) => {
    await SongService.delete(id);
    set({
      todaysTransmission: null,
      status: "idle",
      title: "",
      description: "",
    });
  },
  clearTodayTransmission: () => {
    set({
      todaysTransmission: null,
      status: "idle",
      title: "",
      description: "",
    });
  },
  isValid: () => {
    const { title, media } = get();
    if (!media) {
      return false;
    }
    return title.length > 0 && media.src.length > 0 && media.type.length > 0;
  },
  fetchTodaysTransmission: async (id) => {
    set({ status: "fetching" });
    const todaysTransmission =
      await TransmissionService.FetchTodaysTransmission(id);
    set({ todaysTransmission, status: "idle" });
  },
  setMedia: (media: MediaReference) => {
    set({ media });
  },
  setArt: (art: MediaReference) => {
    if (isEmpty(art)) return;

    set({ art });
  },
  setDescription: (description: string) => {
    set({ description });
  },
  setTitle: (title: string) => {
    set({ title });
  },
  setAuthors: (ids) => {
    set({ authors: ids });
  },
  aggregate: (ownerId) => {
    const { authors, title, media, description } = get();
    return {
      authors,
      media,
      description,
      ownerId,
      title,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    } as FreshSong;
  },
  transmit: async (ownerId) => {
    const state = get();
    let transmission = state.aggregate(ownerId);
    if (state.art) {
      transmission.art = state.art;
    }
    set({ status: "transmitting" });
    const song = await SongService.transmit(transmission);
    set({ todaysTransmission: song.id, status: "idle" });
  },
}));
