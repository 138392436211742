import * as firebase from "firebase/app";
import { ArtistSettings } from "../models/Artist";
import { OwnerId } from "../models/User";



export class SettingsService {
    static changeSetting = (update: any, ownerId: OwnerId): Promise<ArtistSettings> => {
        console.log("change setting",ownerId);
        return new Promise<ArtistSettings>((resolve, reject) => {
            const db = firebase.firestore();
            const settingsDbRef = db.collection('settings').doc(ownerId);
            settingsDbRef.set(update, { merge: true }).then(() => {
                settingsDbRef.get().then(settingsDoc => {
                    const settings = SettingsService.StronglyTypeSettingsData(settingsDoc);
                    return resolve(settings);
                }).catch(reject);
            }).catch(reject);
        });
    }
    static ArtistSettings = (ownerId: OwnerId): Promise<ArtistSettings> => {
        return new Promise<ArtistSettings>((resolve, reject) => {
            const db = firebase.firestore();
            const settingsDbRef = db.collection('settings').doc(ownerId);
            settingsDbRef.get().then(settingsDoc => {
                const settings = SettingsService.StronglyTypeSettingsData(settingsDoc);
                return resolve(settings);
            }).catch(reject);
        });
    }


    static StronglyTypeSettingsData = (artistDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
        const artist_data = { ...artistDoc.data(), id: artistDoc.id } as ArtistSettings;
        return artist_data;
    }
}
