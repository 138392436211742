import {
  IonItem,
  IonAvatar,
  IonText,
  IonLabel,
  IonButtons,
} from "@ionic/react";
import React from "react";
import {
  ArtistSearchResult,
  SongSearchResult,
  useSearch,
} from "../data/store/useSearch";

const SongSearchResultItem: React.FC<SongSearchResult> = ({
  id,
  title,
  artists,
}) => {
  const clear = useSearch((x) => x.clear);
  return (
    <IonItem onClick={clear} routerLink={"/song/" + id}>
      <IonButtons slot="start">
        {artists.map(({ avatar }) => (
          <IonAvatar>
            <img alt="" src={avatar} />
          </IonAvatar>
        ))}
      </IonButtons>
      <IonButtons>
        <IonLabel>{title}</IonLabel>
      </IonButtons>
      <IonButtons slot="end">
        <IonLabel slot="end" color="tertiary">
          {artists.map(({ alias }) => (
            <IonLabel>{alias}</IonLabel>
          ))}
        </IonLabel>
      </IonButtons>
    </IonItem>
  );
};

export const ArtistSearchResultItem: React.FC<ArtistSearchResult> = ({
  id,
  alias,
  avatar,
}) => {
  const clear = useSearch((x) => x.clear);
  return (
    <IonItem onClick={clear} routerLink={"/artist/" + id}>
      <IonButtons slot="start">
        <IonAvatar>
          <img alt="" src={avatar} />
        </IonAvatar>
      </IonButtons>
      <IonText>{alias}</IonText>
    </IonItem>
  );
};

export default SongSearchResultItem;
