import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import Content from '../components/Content';
import SongItem from '../components/SongItem';
import { useSongs } from '../data/store/useSongs';
import { toDate, useIdParam, usePageTitle } from '../util/sugar';


const SongDetail: React.FC = (props) => {
  const id = useIdParam(props);
  const { shouldFetch, fetch } = useSongs();

  const song = useSongs(x => x.retrieve(id));
  const songTitle = song ? song.title + " " + toDate(song.updatedAt) : "loading";
  usePageTitle(songTitle);


  useEffect(() => {
    shouldFetch(id) && fetch(id);
  }, [id, shouldFetch, fetch]);


  return (
    <Content className='background-wall'>
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            {song && <SongItem key={song.id} id={song.id}>
            </SongItem>}
          </IonCol>
        </IonRow></IonGrid>
    </Content>
  );
};

export default SongDetail;