import React, { useLayoutEffect, useState } from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { ItemReorderEventDetail } from "@ionic/core";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonRow,
  IonTitle,
  IonToggle,
  IonReorderGroup,
  IonReorder,
  IonToolbar,
  IonHeader,
  IonFooter,
} from "@ionic/react";
import { ellipsisHorizontalOutline } from "ionicons/icons";

import { useAlbums } from "../data/store/useAlbums";
import { useProfile } from "../data/store/useProfile";
import { useIdParam, usePageTitle } from "../util/sugar";

import Content from "../components/Content";
import SongList from "../components/SongList";
import RequestAccessCard from "../components/RequestAccessCard";
import { UpdateAlbum } from "../components/UpdateAlbum";
import AlbumHeader from "../components/AlbumHeader";
import CompactSongItem from "../components/CompactSongItem";
import ConfirmationAlert from "../components/ConfirmationAlert";

interface AlbumProps extends RouteComponentProps {}

const Album: React.FC<AlbumProps> = (props) => {
  const id = useIdParam(props);
  const { ownerId } = useProfile();
  const { fetch, status, removeFromAlbum, updateAlbum } = useAlbums();
  const album = useAlbums((x) => x.albums[id]);
  const removeAlbum = useAlbums((x) => x.delete);
  const isPrivateAlbum = album && album.isPrivate && ownerId !== album.ownerId;

  const [isEditAlbum, setIsEditAlbum] = useState(false);
  const [alertState, setAlertState] = useState("");

  usePageTitle((album && album.title) || "");

  const deleteSongFromAlbum = (songId: string) => {
    if (ownerId === album.ownerId) {
      removeFromAlbum(id, songId).then();
    }
  };

  const deleteAlbum = async () => {
    await removeAlbum(id);
    props.history.push("/albums");
  };

  const onSortAlbums = async (event: CustomEvent<ItemReorderEventDetail>) => {
    let items = [...album.songs];
    items = event.detail.complete(items);

    await updateAlbum({ ...album, songs: items });
  };

  useLayoutEffect(() => {
    fetch(id);
    // eslint-disable-next-line
  }, []);

  if (isPrivateAlbum) {
    return (
      <Content>
        <RequestAccessCard
          message={
            "You do not have permission to view the content of this page"
          }
        />
      </Content>
    );
  }

  if (!album || !album.songs || !album.ownerId || !album.title) return <></>;

  return (
    <Content>
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <AlbumHeader album={album} />

            {album.ownerId === ownerId && (
              <IonRow class="ion-justify-content-between  ion-align-items-center ion-padding-horizontal">
                <IonButton
                  onClick={() => setAlertState("delete album")}
                  color="danger"
                  disabled={!isEditAlbum}
                >
                  Delete Album
                </IonButton>

                <IonRow class="ion-align-items-center">
                  <IonLabel class="ion-margin-horizontal">Edit Album</IonLabel>
                  <IonToggle
                    checked={isEditAlbum}
                    onClick={() => setIsEditAlbum((prevState) => !prevState)}
                  />
                </IonRow>
              </IonRow>
            )}

            {isEditAlbum && (
              <UpdateAlbum
                album={album}
                onClose={() => setIsEditAlbum(false)}
              />
            )}

            {!!album.songs.length ? (
              isEditAlbum ? (
                <IonReorderGroup
                  disabled={false}
                  onIonItemReorder={onSortAlbums}
                >
                  {album.songs.map((song) => {
                    return (
                      <>
                        <IonToolbar key={song.id}>
                          <IonHeader>
                            <CompactSongItem id={song.id} />
                          </IonHeader>
                          <IonReorder slot="start" />
                          <IonFooter>
                            <IonButton
                              fill="outline"
                              color="danger"
                              expand="full"
                              onClick={() => setAlertState(`delete ${song.id}`)}
                            >
                              Delete
                            </IonButton>
                          </IonFooter>
                        </IonToolbar>
                        <ConfirmationAlert
                          message="Do you want to delete this song from album ?"
                          onConfirm={() => deleteSongFromAlbum(song.id)}
                          onDismiss={() => setAlertState("")}
                          isAlertOpen={alertState === `delete ${song.id}`}
                        />
                      </>
                    );
                  })}
                </IonReorderGroup>
              ) : (
                <SongList
                  status={status}
                  fetchMore={() => {}}
                  onDeleteSong={deleteSongFromAlbum}
                  songRefs={album.songs}
                  hideFetchButton
                />
              )
            ) : (
              <IonCard>
                <IonCardHeader color="tertiary">
                  <IonTitle>No Songs Found!</IonTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItemDivider color="clear" />
                  Use the Song Menu Modal to add your songs to this album!
                  <IonItemDivider color="clear" />
                  The button for the song menu looks like this:
                  <IonIcon
                    size="large"
                    icon={ellipsisHorizontalOutline}
                    style={{ marginLeft: 10, verticalAlign: "middle" }}
                  />
                </IonCardContent>
              </IonCard>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>

      <ConfirmationAlert
        message="Do you want to delete this album ?"
        onConfirm={async () => {
          await deleteAlbum();
        }}
        onDismiss={() => {
          setAlertState("");
        }}
        isAlertOpen={alertState === "delete album"}
      />

      <IonLoading isOpen={status === "fetching" || !album} />
    </Content>
  );
};

export default withRouter(Album);
