import React, { useLayoutEffect, useState } from "react";
import {
  IonAlert,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from "@ionic/react";
import { addOutline, listOutline } from "ionicons/icons";

import { menuController } from "@ionic/core";
import { useContextModals } from "../data/store/useContextModals";
import { useProfile } from "../data/store/useProfile";

import isEmpty from "lodash.isempty";
import { useAlbums } from "../data/store/useAlbums";
import { AlbumId } from "../models/Albums";

interface PopoverState {
  showPopover: boolean;
  event?: Event;
}

interface AvailableAlbums {
  popoverDismiss: () => void;
  popoverState: PopoverState;
  onClose?: () => void;
}

const AvailableAlbums: React.FC<AvailableAlbums> = ({
  popoverDismiss,
  popoverState,
  onClose,
}) => {
  const { ownerId } = useProfile();
  const [showAlert, setShowAlert] = useState(false);
  const { clearSongModalContext, modalSong } = useContextModals();
  const { fetchAll, isInAlbum, addToAlbum, albumsByUser } = useAlbums();

  const myAlbums = albumsByUser[ownerId];

  const closeAndClear = async () => {
    popoverDismiss();
    clearSongModalContext();
    onClose && onClose();
    await menuController.close();
  };

  const addToMyAlbum = (albumId: AlbumId) => {
    if (isInAlbum(albumId, modalSong.id)) {
      setShowAlert(true);
      return;
    }

    addToAlbum(albumId, modalSong.id).then(() => {
      closeAndClear();
    });
  };

  useLayoutEffect(() => {
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPopover
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={popoverDismiss}
    >
      <IonList>
        <IonItem routerLink="/album-create" onClick={closeAndClear}>
          <IonIcon slot="start" icon={addOutline} />
          <IonLabel>New Album</IonLabel>
        </IonItem>

        {!isEmpty(myAlbums) &&
          myAlbums.map((myAlbums) => (
            <IonItem
              key={myAlbums.id}
              detail={false}
              routerLink="#"
              onClick={() => addToMyAlbum(myAlbums.id)}
            >
              <IonIcon slot="start" icon={listOutline} />
              <IonLabel>{myAlbums.title}</IonLabel>
            </IonItem>
          ))}
      </IonList>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header="Oops..."
        message="Looks like this song is already in the Album."
        buttons={["OK"]}
      />
    </IonPopover>
  );
};

export default AvailableAlbums;
