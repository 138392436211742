import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItemDivider, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import AsyncArtistButton from '../components/AsyncArtistButton';
import Config from '../components/Config';
import Content from '../components/Content';
// import GroupAdmin from '../components/GroupAdmin';
import { useArtists } from '../data/store/useArtists';
import { useProfile } from '../data/store/useProfile';
import { useRoles } from '../data/store/useRoles';
import { OwnerId } from '../models/User';
import { usePageTitle } from '../util/sugar';
import Issues from './Issues';

interface OwnerParams {
    ownerId: OwnerId
}

const NotAuthorized: React.FC<OwnerParams> = ({ ownerId }) => <IonCard>
    <IonCardHeader color="primary">
        <IonCardTitle>
            <IonTitle>
                Group Status
        </IonTitle>
        </IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
        <IonItemDivider color="clear" />
        <IonText>
            Your account is missing administrative authority.
</IonText>
        <IonItemDivider color="clear" />
        <IonText>
            If this is an error, please                         <IonButton routerLink={"/issue/group/" + ownerId}>
                Report an Issue
</IonButton>

        </IonText>

    </IonCardContent>
</IonCard>

const UsersAdmin: React.FC = () => {
    const { fetchAll, all } = useArtists();
    useEffect(() => {
        fetchAll();
    }, [all, fetchAll]);
    return <IonCard>
        <IonCardHeader color="primary">
            <IonCardTitle>
                <IonTitle>
                    Users
        </IonTitle>
            </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
            {all.map(artistId => {
                return <AsyncArtistButton key={artistId} id={artistId} />
            })}
        </IonCardContent>
    </IonCard>
}



const Admin: React.FC = () => {
    usePageTitle("Admin");
    const { ownerId } = useProfile();
    const { role } = useRoles();
    const authority = role && role.authority;
    const hasDashboard = authority && authority.includes("admin");

    const [showIssues, setShowIssues] = useState(false);
    // const [showGroups, setShowGroups] = useState(false);
    const [showConfig, setShowConfig] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    if (!ownerId || !role) {
        return <Content><IonTitle>
            <IonSpinner name="bubbles" />
        </IonTitle></Content >
    }
    return (
        <Content>
            {hasDashboard ? <>
                <IonToolbar>
                    <IonButton fill="solid" color={!showIssues ? "tertiary" : "favorite"} onClick={() => { setShowIssues(x => !x) }}>
                        Issues
                </IonButton>
                    <IonButton fill="solid" color={!showUsers ? "tertiary" : "favorite"} onClick={() => { setShowUsers(x => !x) }}>
                        Users
                    </IonButton>
                    {/* <IonButton fill="solid" color={!showGroups ? "tertiary" : "favorite"} onClick={() => { setShowGroups(x => !x) }}>
                        Groups
                    </IonButton> */}
                    <IonButton fill="solid" color={!showConfig ? "tertiary" : "favorite"} onClick={() => { setShowConfig(x => !x) }}>
                        Public Config
                    </IonButton>
                    <IonButton fill="solid" color="tertiary" routerLink="/concert">
                        Concert
                    </IonButton>
                </IonToolbar>
                {/* {showGroups && <GroupAdmin />} */}
                {showUsers && <UsersAdmin />}
                {showIssues && <Issues />}
                {showConfig && <Config />}
            </> : ownerId ? <NotAuthorized ownerId={ownerId} /> : <IonButton routerLink="/login">
                Login
            </IonButton>}
        </Content>
    );
};

export default Admin;
