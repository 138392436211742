import React, { FC, useEffect, useRef } from "react";

import { IonButton, isPlatform } from "@ionic/react";

import MediaService from "../services/MediaService";
import { usePlayer } from "../data/store/usePlayer";
import { usePlaylist } from "../data/store/usePlaylist";
import { useSongs } from "../data/store/useSongs";

interface PlayerProps {}

const Player: FC<PlayerProps> = () => {
  const { playNow, pauseNow } = usePlayer();
  const { nextSong, nowPlayingId } = usePlaylist();
  const { retrieve } = useSongs();
  const playerRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (playerRef.current) {
      MediaService.please().setPlayerRef(playerRef);
      (playerRef.current as any).onleavepictureinpicture = () => {
        setTimeout(() => {
          MediaService.please().play();
        }, 1);
      };
      document.addEventListener("fullscreenchange", () => {
        // document.fullscreenElement will point to the element that
        // is in fullscreen mode if there is one. If there isn't one,
        // the value of the property is null.

        setTimeout(() => {
          MediaService.please().play();
        }, 500);
      });
    }
  }, [playerRef]);

  return (
    <IonButton
      onClick={() => {
        if (
          playerRef.current &&
          playerRef.current.requestFullscreen &&
          !isPlatform("ios")
        ) {
          (playerRef.current as any).requestFullscreen();
        } else if (
          playerRef.current &&
          (playerRef.current as any).webkitRequestFullscreen &&
          !isPlatform("ios")
        ) {
          (playerRef.current as any).webkitRequestFullscreen();
        } else if (
          playerRef.current &&
          (playerRef.current as any).requestPictureInPicture
        ) {
          (playerRef.current as any).requestPictureInPicture();
        }
      }}
    >
      <video
        webkit-playsinline
        playsInline
        onEnded={() => {
          const song = retrieve(nextSong());
          song &&
            MediaService.please()
              .setSong(song)
              .then(() => {
                MediaService.please().play();
              });
        }}
        onPlay={() => {
          playNow();
        }}
        onPause={() => {
          pauseNow();
        }}
        controlsList="nodownload"
        id="sadfam-media"
        crossOrigin={"anonymous"}
        style={{ height: "100%" }}
        ref={playerRef}
      />
    </IonButton>
  );
};

export default React.memo(Player);
