import React, { memo, useEffect } from 'react';
import AsyncArtistButton from '../components/AsyncArtistButton';
import Content from '../components/Content';
import P2PAudioIO from '../components/P2PAudioIO';
import { usePlayer } from '../data/store/usePlayer';
import { useProfile } from '../data/store/useProfile';
import { useSpaces } from '../data/store/useSpaces';
import { usePageTitle } from '../util/sugar';


const Concert: React.FC = () => {
    const { ownerId } = useProfile();
    const { pauseNow } = usePlayer();

    const { join, leave, members } = useSpaces();
    usePageTitle("Concert");
    useEffect(() => {
        return () => {
            ownerId && leave("concert", ownerId);
        }
    }, [leave, ownerId])
    const participants = members && Object.values(members);
    return (
        <Content>
            <P2PAudioIO onReady={() => {
                pauseNow();
                console.log(ownerId, " joining concert",);
                ownerId !== undefined && join("concert", ownerId);
            }} />
            {participants && participants.map(({ artist, status }) =>
                <AsyncArtistButton key={artist.id} disabled={true} id={artist.id} />
            )
            }
        </Content >
    );
};



export default memo(Concert);
