import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { ArtistId } from "../models/Artist";
import { ArtistService } from "./ArtistService";
import ObserverService from "./ObserverService";
import { ProfileService } from "./ProfileService";

(window as any).authChangeWatcher = Function;


const firebaseConfig = {
  // DEV
//   apiKey: 'AIzaSyCXPoneik98_43585nzblZBcmAdgoOVG8A',
//   authDomain: 'sadfam-dev.firebaseapp.com',
//   projectId: 'sadfam-dev',
//   storageBucket: 'sadfam-dev.appspot.com',
//   messagingSenderId: '720747326571',
//   appId: '1:720747326571:web:835d317f8804d6d14d952e'

  // TODO: clarify prod credentials
  // PROD
  apiKey: "AIzaSyC_iA41MR6ZyLD39gI_oNPeOOfLKGZ2QQw",
  authDomain: "song-a-day.firebaseapp.com",
  databaseURL: "https://song-a-day.firebaseio.com",
  projectId: "firebase-song-a-day",
  storageBucket: "firebase-song-a-day.appspot.com",
  messagingSenderId: "199112265328",
  appId: "1:199112265328:web:e7d06ae17bb91d18bbeeb5",
  measurementId: "G-WP7BZH9L4L"
};
firebase.initializeApp(firebaseConfig);



export class LoginService {
    static login = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope("https://www.googleapis.com/auth/userinfo.profile")
        provider.addScope("https://www.googleapis.com/auth/userinfo.email");
        firebase.auth().signInWithPopup(provider);
    }
    static logout = () => {
        return firebase.auth().signOut()
    }
    static watchAuthState = (onLoggedIn: (artistId: ArtistId, ownerId: string) => void, onLoggedOut: () => void) => {
        const isObserving = ObserverService.please().exist("authentication");
        if (isObserving) {
            console.log("authstate observing already")
            return;
        } else {
            const authObserver = firebase.auth().onAuthStateChanged(function (user) {
                if (user && user.providerData[0] && user.providerData[0].email === null && user.email !== null) {
                    user.providerData[0].email = user.email;
                }
                const providerData = user && { ...user, ...user.providerData[0] };
                if (user && providerData && "email" in providerData) {
                    // User is signed in.
                    const artistId = ArtistService.calculateArtistId((providerData as any)['email'])

                    const promptForNewAvatarAndLogin = () => {
                        const alias = window.prompt("Alias not found, what do we call you?");
                        if (alias) {
                            let change = { ownerId: user.uid } as any;
                            if (alias)
                                change["alias"] = alias;
                            ProfileService.changeProfile(change, artistId).then(() => {
                                onLoggedIn(artistId, user.uid);

                            });
                        } else {
                            onLoggedIn(artistId, user.uid);
                        }

                    }

                    ArtistService.fetch(artistId).catch(promptForNewAvatarAndLogin).then((artist) => {
                        if (artist && typeof artist.alias === undefined) {
                            promptForNewAvatarAndLogin();
                        } else {
                            console.log("Successfully logged in as " + (artist as any || { alias: "" }).alias);
                            onLoggedIn(artistId, user.uid);
                        }
                    })
                } else {
                    onLoggedOut();
                }
            });
            ObserverService.please().add("authentication", authObserver);

        }
    }
}
