import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonLoading, IonRow } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import AsyncArtistButton from '../components/AsyncArtistButton';
import Content from '../components/Content';
import { useProfile } from '../data/store/useProfile';
import { useRoles } from '../data/store/useRoles';
import HostConfig from '../HostConfig';
import { LoginService } from '../services/LoginService';
import './Login.scss';

interface OwnProps extends RouteComponentProps { }


interface LoginProps extends OwnProps, RouteComponentProps { }

const Login: React.FC<LoginProps> = () => {
  const { authorId } = useProfile();
  const { role } = useRoles()
  const status = useProfile(x => x.status);
  const authenticated = status === "authenticated";
  return (
    <Content style={{ "--background": "#519f6b" }}>

      <IonCardHeader>
        {<IonAvatar style={{ width: 108, height: 108, margin: 'auto' }}>
          <img src={HostConfig.icon} alt={"Song a Day for a Month"} />
        </IonAvatar>}
      </IonCardHeader>
      <IonCardContent>

        <IonRow>
          <IonCol sizeMd="6" pushMd="3">
            {status === 'unauthenticated' && <IonButton color='primary' onClick={() => { LoginService.login() }} type="submit" expand="block">Login with Google</IonButton>}
            <IonLoading isOpen={status === "unknown"} />
          </IonCol>
        </IonRow>
        <IonRow>

          <IonCol sizeMd="6" pushMd="3">

            {authenticated && <IonCard>
              <IonCardHeader>
                Welcome to song a day {authorId && <AsyncArtistButton id={authorId} />}

              </IonCardHeader>
              <IonCardContent>
                <IonButton fill='outline' routerLink='/conversations?thread=start'>
                  Conversations
          </IonButton>
                <IonButton fill='outline' routerLink='/transmit'>
                  Transmit
          </IonButton>
                <IonButton fill='outline' routerLink='/account'>
                  Account
          </IonButton>
                <IonButton fill='outline' routerLink='/songs'>
                  Songs
          </IonButton>
                {role && role.authority && role.authority.includes("admin") && <IonButton fill='outline' routerLink='/admin'>
                  Admin
          </IonButton>
                }

              </IonCardContent>

            </IonCard>}
          </IonCol>
        </IonRow>
      </IonCardContent>
    </Content>
  );
};

export default withRouter(Login);