import React, { useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonToggle,
} from "@ionic/react";
import { MediaReference } from "../models/Song";
import { useProfile } from "../data/store/useProfile";
import Uploader from "../components/Uploader";
import { Playlist } from "../models/Playlists";
import { usePlaylists } from "../data/store/usePlaylists";

interface UpdatePlaylistProps {
  playlist: Playlist;
  onClose: () => void;
}

export const UpdatePlaylist = ({ playlist, onClose }: UpdatePlaylistProps) => {
  const { updatePlaylist, status } = usePlaylists();
  const { authorId, ownerId } = useProfile();
  const [name, setName] = useState<string>(playlist.title);
  const [art, setArt] = useState<MediaReference>(playlist.art);
  const [showArt, setShowArt] = useState<boolean>(
    playlist.art && !!playlist.art.src
  );
  const [isPlaylistVisible, setIsPlaylistVisible] = useState<boolean>(
    playlist.isPrivate
  );
  const [isShowEditForm, setIsShowEditForm] = useState<boolean>(false);
  const isUploadedArt = showArt ? !art.src : false;
  const isBtnDisabled =
    !ownerId ||
    !authorId ||
    isUploadedArt ||
    name === "" ||
    status === "fetching";

  const toggleVisibleEditForm = () => {
    setIsShowEditForm((prevState) => !prevState);
  };

  const toggleShowArt = () => {
    setShowArt((prevState) => !prevState);
    showArt && setArt({} as MediaReference);
  };

  const savePlaylist = async () => {
    await updatePlaylist({
      ...playlist,
      title: name,
      art,
      isPrivate: isPlaylistVisible,
    });
    onClose();
  };

  return isShowEditForm ? (
    <IonCard>
      <IonCardContent>
        <IonItem>
          <IonLabel position="stacked" color="primary">
            Title
          </IonLabel>
          <IonInput
            spellCheck={false}
            autocapitalize="off"
            value={name}
            onIonChange={(e) => setName(e.detail.value!)}
            required
          />
        </IonItem>

        <IonItem lines="none">
          <IonLabel color="primary">Private playlist</IonLabel>
          <IonToggle
            checked={isPlaylistVisible}
            onClick={() => setIsPlaylistVisible((prevState) => !prevState)}
          />
        </IonItem>

        <IonItem>
          <IonLabel color="primary">Upload playlist art</IonLabel>
          <IonToggle checked={showArt} onClick={toggleShowArt} />
        </IonItem>

        {showArt && (
          <Uploader
            message="select an image file"
            collection={"covers"}
            onComplete={(media) => {
              if (media) {
                setArt(media);
              }
            }}
          />
        )}

        <IonButton
          onClick={savePlaylist}
          disabled={isBtnDisabled}
          class="ion-margin"
        >
          Update
        </IonButton>
        <IonButton
          onClick={toggleVisibleEditForm}
          slot="end"
          color="danger"
          class="ion-margin"
        >
          Close edit form
        </IonButton>
      </IonCardContent>
    </IonCard>
  ) : (
    <IonButton
      class="ion-margin-vertical"
      fill="outline"
      expand="full"
      onClick={toggleVisibleEditForm}
    >
      Edit playlist Information
    </IonButton>
  );
};
