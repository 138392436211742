import firebase from "firebase";

export interface Timestamp {
    seconds: number;
    nanoseconds: number;
};

export const BeginningOfTime = firebase.firestore.Timestamp.fromDate(new Date(-1));
export const EndOfTime = firebase.firestore.Timestamp.fromDate(new Date(-1));
export interface HasTimestamp {
    updatedAt: Timestamp;
}

export interface DocumentRef extends HasTimestamp {
    collection: string,
    id: string,
    authorId: string,
}
export interface Timeline {
    days: Day[],
    cursor: string
}

export interface Day {
    transmissions: DocumentRef[],
    date: string
}
