import React from 'react'
import {
  IonButton,
  IonCol,
  IonIcon,
  IonList,
  IonProgressBar,
  IonText,
} from '@ionic/react'
import { arrowDownOutline, playOutline } from 'ionicons/icons'
import { useVirtual } from 'react-virtual'
import { usePlayer } from '../data/store/usePlayer'
import { usePlaylist } from '../data/store/usePlaylist'
import { songHookStatus, useSongs } from '../data/store/useSongs'
import { SongRef } from '../models/Song'
import MediaService from '../services/MediaService'
import SongItem from './SongItem'
import Spacer from './Spacer'

interface OwnProps {
  songRefs: SongRef[]
  fetchMore: () => void
  onDeleteSong?: (id: string) => void
  status: songHookStatus
  nextPageText?: string
  hideFetchButton?: boolean
  showDeletedButton?: boolean
}

interface SongListProps extends OwnProps {}

const SongList: React.FC<SongListProps> = ({
  songRefs,
  status,
  fetchMore,
  hideFetchButton,
  showDeletedButton,
  nextPageText,
  onDeleteSong = () => {},
}) => {
  const { queueSong, setNowPlaying } = usePlaylist()
  const isPaused = usePlayer((x) => x.isPaused)
  const retrieve = useSongs((x) => x.retrieve)
  const parentRef = React.useRef<HTMLIonListElement>(null)

  const rowVirtualizer = useVirtual({
    size: songRefs.length,
    parentRef,
  })

  const playAll = () => {
    if (isPaused) {
      const first = retrieve(songRefs[0].id)
      first &&
        MediaService.please()
          .setSong(first)
          .then(() => {
            first && setNowPlaying(first.id)
            MediaService.please().play()
          })
    }
    songRefs.forEach((x) => queueSong(x.id))
  }

  return (
    <>
      {songRefs.length > 1 && (
        <IonCol size="12">
          <IonButton
            fill="outline"
            color="favorite"
            expand="full"
            onClick={playAll}
            style={{
              padding: '1px',
              margin: '0 16px',
            }}
          >
            <IonText
              style={{
                textShadow: 'white 1px 0px 1px',
              }}
            >
              Play All
            </IonText>
            <IonIcon class="ion-margin-horizontal" icon={playOutline} />
          </IonButton>
        </IonCol>
      )}

      <IonList ref={parentRef} style={{ background: 'clear' }} color="light">
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          const songRef = songRefs[virtualRow.index]

          return (
            <div key={songRef.id}>
              <SongItem id={songRef.id} clearDeletedSong={onDeleteSong} />
              {showDeletedButton && (
                <IonButton
                  expand="full"
                  color="danger"
                  onClick={() => onDeleteSong(songRef.id)}
                >
                  Remove song from list
                </IonButton>
              )}
            </div>
          )
        })}
      </IonList>

      {!hideFetchButton && (
        <IonCol size="12">
          {status === 'idle' ? (
            !hideFetchButton && (
              <IonButton
                fill="outline"
                color="tertiary"
                expand="full"
                onClick={fetchMore}
                style={{
                  padding: '1px',
                  margin: '0 16px',
                }}
              >
                <IonText style={{}}>{nextPageText || 'Load More'}</IonText>
                <IonIcon icon={arrowDownOutline} />
              </IonButton>
            )
          ) : (
            <IonProgressBar
              style={{ height: 52 }}
              type="indeterminate"
              color="tertiary"
            />
          )}
        </IonCol>
      )}
      <Spacer height={100} />
    </>
  )
}

export default SongList
