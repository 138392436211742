import firebase, { firestore } from 'firebase/app'
import { FreshSong, Song, SongId, SongRef } from '../models/Song'
import { Timestamp } from '../models/Timeline'
import { findHashtags } from '../util/sugar'
import { CommentThreadService } from './CommentThreadService'
import { DirectoryService } from './DirectoryService'
import ObserverService from './ObserverService'
import { SearchService } from './SearchService'

export class SongService {
  static fetchRevisions(songId: string) {
    return new Promise<Song[]>((resolve, reject) => {
      var db = firebase.firestore()
      db.collection('songs')
        .where('original', '==', songId)
        .get()
        .then((songDocs) => {
          let songs = songDocs.docs.map((x) =>
            SongService.StronglyTypeSongData(x),
          )

          SongService.fetch(songId)
            .then((song) => {
              if (!songs.map((x) => x.id).includes(song.id)) songs.push(song)
              resolve(songs)
            })
            .catch(reject)
        })
        .catch((err) => {
          console.error(err, 'fetch revise failed')
          reject(err)
        })
    })
  }

  static observe(
    onFreshSong: (song: Song) => void,
    onRevokeSong: (songId: string) => void,
  ) {
    if (ObserverService.please().exist('latest-song')) {
      console.log('observing already')
      return
    }
    const db = firebase.firestore()
    const watcher = db
      .collection('songs')
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .onSnapshot((songQuery) => {
        songQuery.docChanges().forEach(function (change) {
          if (change.type === 'added') {
            const freshSong = {
              ...change.doc.data(),
              id: change.doc.id,
            } as Song
            onFreshSong(freshSong)
          }
          if (change.type === 'modified') {
            const freshSong = {
              ...change.doc.data(),
              id: change.doc.id,
            } as Song
            onFreshSong(freshSong)
          }
          if (change.type === 'removed') {
            // Lets make sure the song is actually not there...
            SongService.fetch(change.doc.id).catch(() => {
              onRevokeSong(change.doc.id)
            })
          }
        })

        for (const songDoc of songQuery.docs) {
          const song = SongService.StronglyTypeSongData(songDoc)
          onFreshSong(song)
        }
      })
    ObserverService.please().add('songs', watcher)
  }

  static async revise(original: string, song: FreshSong) {
    const { description, authors, ownerId, title, media, artists } = song
    return new Promise<Song>((resolve, reject) => {
      var db = firebase.firestore()
      db.collection('songs')
        .doc(original)
        .update({ original })
        .then(() => {
          SongService.transmit({
            description,
            media,
            authors,
            artists,
            ownerId,
            title,
            original,
            updatedAt: firestore.FieldValue.serverTimestamp(),
          })
            .then((song) => {
              resolve(song)
            })
            .catch(reject)
        })
        .catch(reject)
    })
  }

  static toRef(song: Song): SongRef {
    return { id: song.id, updatedAt: song.updatedAt } as SongRef
  }

  static transmit = async (freshSong: FreshSong): Promise<Song> => {
    console.log(freshSong)

    const artists = await Promise.all(
      freshSong.authors.map(async (artistId) =>
        DirectoryService.fetch(artistId),
      ),
    )
    freshSong.artists = {}
    for (const artist of artists) {
      let { id, ownerId, alias, avatar } = artist
      if (avatar === undefined) {
        avatar = ''
      }
      freshSong.artists[id] = { id, ownerId, alias, avatar }
      freshSong.privacy = artist.privacy || freshSong.privacy || 'public'
    }
    const tags = findHashtags(freshSong.description)
    if (tags) {
      freshSong.tags = tags
    }
    if (freshSong.title === ' ') {
      freshSong.title = 'Untitled'
    }

    const { id } = await CommentThreadService.initialize()
    return new Promise((resolve, reject) => {
      var db = firebase.firestore()
      const songRef = db.collection('songs').doc(id)
      songRef.set(freshSong).then(() => {
        songRef
          .get()
          .then((songDoc) => {
            if (songDoc !== undefined) {
              const song = SongService.StronglyTypeSongData(songDoc)
              SearchService.please().pushSongToIndex(song)
              resolve(song)
            }
          })
          .catch(reject)
      })
    })
  }

  static updateSong = async (id: SongId, updatedSong: FreshSong) => {
    const freshSong = { ...updatedSong }

    !freshSong.art && delete freshSong.art
    freshSong.artists = {}

    const artists = await Promise.all(
      freshSong.authors.map(async (artistId) =>
        DirectoryService.fetch(artistId),
      ),
    )

    for (const artist of artists) {
      const { id, ownerId, alias, avatar } = artist

      freshSong.artists[id] = { id, ownerId, alias, avatar: avatar || '' }
      freshSong.privacy = artist.privacy || updatedSong.privacy || 'public'
    }

    return new Promise<FreshSong>((resolve, reject) => {
      const db = firestore()
      if (id) {
        db.collection('songs')
          .doc(id)
          .set({ ...freshSong }, { merge: true })
          .then(() => {
            resolve(freshSong)
            return freshSong
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        reject()
      }
    })
  }

  static getFileExtension = (url: string) => {
    return new Promise<string>(async (resolve, reject) => {
      const songRef = firebase.storage().refFromURL(url)

      const ext = await songRef
        .getMetadata()
        .then()
        .then((data) => data.name.split('.').slice(-1)[0])
        .catch((err) => {
          console.error(err)
          reject(err)
        })

      resolve(ext)
      return ext
    })
  }

  static fetch = (id: string) => {
    return new Promise<Song>((resolve, reject) => {
      const db = firebase.firestore()
      if (!id || id === '') {
        reject()
      } else {
        db.collection('songs')
          .doc(id)
          .get()
          .then((songDoc) => {
            if (!songDoc.exists) {
              resolve({} as Song)
            }
            resolve(SongService.StronglyTypeSongData(songDoc))
          })
          .catch(reject)
      }
    })
  }

  static fetchMany(queue: SongId[]): Promise<Song[]> {
    return new Promise((resolve, reject) => {
      var db = firebase.firestore()
      const directoryEntriesRef = db
        .collection('songs')
        .where(firebase.firestore.FieldPath.documentId(), 'in', queue)
      directoryEntriesRef
        .get()
        .then((directoryEntriesCollection) => {
          let matches: Song[] = []
          for (const directoryEntryDoc of directoryEntriesCollection.docs) {
            const entry = SongService.StronglyTypeSongData(directoryEntryDoc)
            matches.push(entry)
          }
          resolve(matches)
        })
        .catch(reject)
    })
  }

  static delete = (id: string): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      var db = firebase.firestore()
      if (!id || id === '') {
        return
      }
      const { original } = await SongService.fetch(id)
      await db
        .collection('songs')
        .doc(id)
        .delete()
        .then(async () => {
          console.log(original)
          SearchService.please().removeSongFromIndex(id)
          resolve()
        })
      if (original && typeof original && original !== id) {
        db.collection('songs')
          .where('original', '==', original)
          .get()
          .then((queryResult) => {
            if (queryResult.size === 1) {
              db.collection('songs')
                .doc(original)
                .update({ original: firebase.firestore.FieldValue.delete() })
            }
          })
      }
    })
  }
  static fetchSome = (limit: number): Promise<Song[]> => {
    console.log('OK', limit)
    return new Promise((resolve, reject) => {
      var db = firebase.firestore()
      db.collection('songs')
        .orderBy('updatedAt')
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc)
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }
  static fetchMore = (limit: number, timestamp: Timestamp): Promise<Song[]> => {
    if (timestamp.seconds === -1) {
      console.log('FIRST LOAD', limit)
      return SongService.fetchSome(limit)
    }
    console.log(limit)
    return new Promise((resolve, reject) => {
      var db = firebase.firestore()
      db.collection('songs')
        .orderBy('updatedAt')
        .endBefore(new Date(timestamp.seconds * 1000))
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc)
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  /**
   * Fetch some songs with a particular hashtag
   *
   * @param tag the hashtag to query
   * @param limit how many songs to get
   */
  static fetchSongsWithHashtag = (tag: string) => {
    return new Promise<Song[]>((resolve, reject) => {
      var db = firebase.firestore()
      db.collection('songs')
        .where('tags', 'array-contains', tag)
        .orderBy('updatedAt', 'desc')
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            resolve([])
          }
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc)
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }
  /**
   * Fetch some songs with a particular hashtag
   *
   * @param tag the hashtag to query
   * @param limit how many songs to get
   */
  static fetchSongsWithHashtagInComments = (tag: string) => {
    return new Promise<SongRef[]>((resolve, reject) => {
      var db = firebase.firestore()
      db.collection('threads')
        .where('tags', 'array-contains', tag)
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot.docs)
          if (querySnapshot.empty) {
            resolve([])
          }
          const songBatch = querySnapshot.docs.map((x) => {
            const updatedAt: Timestamp = { seconds: 0, nanoseconds: 0 }
            return { id: x.id, updatedAt }
          })
          console.log(songBatch)
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  static StronglyTypeSongData = (
    songDoc:
      | firestore.QueryDocumentSnapshot<firestore.DocumentData>
      | firestore.QueryDocumentSnapshot<firestore.DocumentData>
      | firestore.DocumentSnapshot<firestore.DocumentData>,
  ) => {
    const song_data = { ...songDoc.data(), id: songDoc.id } as Song
    return song_data
  }
}
