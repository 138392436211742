import { IonButton, IonCol, IonGrid, IonList, IonLoading, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import ArtistItem from '../components/ArtistItem';
import Content from '../components/Content';
import { useArtists } from '../data/store/useArtists';
import { usePageTitle } from '../util/sugar';

interface ArtistListProps { };

const ArtistList: React.FC<ArtistListProps> = () => {
  const { observing, status, observeDirectory } = useArtists();
  const roster = useArtists(x => x.roster);
  const empty = roster.length === 0;

  usePageTitle("Artists");

  useEffect(() => {
    !observing && observeDirectory();
  }, [empty, observeDirectory, observing])

  const parentRef = React.useRef<HTMLIonListElement>(null)

  return (
    <Content className='chill'>
      <IonGrid>
        <IonRow>
          <IonLoading isOpen={status === "fetching" || status === "initial"} />
          <IonCol sizeMd="6" offsetMd="3" sizeXs='12'>
            <IonList ref={parentRef}>
              {roster.map(artistId => <ArtistItem key={artistId} id={artistId} />)}
              <IonButton fill="outline" expand="full" onClick={() => { observeDirectory() }}>
                Load More
              </IonButton>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default React.memo(ArtistList);
