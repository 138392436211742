import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  IonButton,
  IonImg,
  IonButtons,
  IonLoading,
  IonSearchbar,
  IonRow,
  IonCard,
  IonToolbar,
  IonItemDivider,
  IonText,
  IonIcon,
} from "@ionic/react";
import AsyncArtistButton from "../components/AsyncArtistButton";
import { Album } from "../models/Albums";
import HostConfig from "../HostConfig";
import { useAlbums } from "../data/store/useAlbums";
import { useProfile } from "../data/store/useProfile";
import { useArtists } from "../data/store/useArtists";
import isEmpty from "lodash.isempty";
import { OwnerId } from "../models/User";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import "../components/styles.scss";

interface PersonalAlbums {
  id: OwnerId;
}

const artistItemDefaultStyle = {
  padding: "5px",
  background: "white",
  borderRadius: "8px",
  margin: "0 16px",
};

const PersonalAlbums: React.FC<PersonalAlbums> = ({ id }) => {
  const { ownerId } = useProfile();
  const {
    status: albumsStatus,
    fetchAll,
    updateAlbum,
    albums,
    albumsByUser,
  } = useAlbums();
  const { status } = useArtists((state) => state);
  const [currentAlbums, setCurrentAlbums] = useState<Album[]>([]);
  const [query, setQuery] = useState("");
  const hasPermission = id === ownerId;

  const toggleAlbumVisible = (album: Album) => async () => {
    const freshAlbum = { ...album, isPrivate: !album.isPrivate };
    await updateAlbum(freshAlbum);
  };

  useEffect(() => {
    const allPersonalAlbums = albumsByUser[id];

    setCurrentAlbums(allPersonalAlbums);
  }, [albumsByUser, id]);

  useLayoutEffect(() => {
    fetchAll().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IonRow style={artistItemDefaultStyle} class="ion-justify-content-center">
        {/*<IonSearchbar onIonChange={(e) => setQuery(e.detail.value || "")} />*/}

        {!isEmpty(currentAlbums) &&
          currentAlbums
            .filter((item) => item.title.toLowerCase().includes(query))
            .map((filteredItem: Album, index: number) => {
              const album = albums[filteredItem.id];

              return album.isPrivate && ownerId !== album.ownerId ? (
                <></>
              ) : (
                <IonCard key={album.id} className="albumArtistPageItem">
                  <IonButtons
                    class="ion-justify-content-between  ion-align-items-center"
                    style={{
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    {album.art && album.art.src ? (
                      <IonImg
                        src={album.art.src}
                        slot="start"
                        style={{
                          height: "120px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <IonImg
                        src={HostConfig.icon}
                        slot="start"
                        style={{ height: "120px", width: "100%" }}
                      />
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        width: "100%",
                        padding: 5,
                      }}
                    >
                      <IonButton routerLink={"/album/" + album.id}>
                        <IonText
                          color="dark"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {album.title}
                        </IonText>
                      </IonButton>

                      <IonToolbar>
                        <div slot="secondary">
                          <AsyncArtistButton
                            key={index}
                            id={album.authorId}
                            variant="album"
                          />
                        </div>

                        {hasPermission && (
                          <IonButton
                            slot="primary"
                            onClick={toggleAlbumVisible(album)}
                          >
                            <IonIcon
                              color={album.isPrivate ? undefined : "warning"}
                              icon={
                                album.isPrivate ? eyeOffOutline : eyeOutline
                              }
                            />
                          </IonButton>
                        )}
                      </IonToolbar>
                    </div>
                  </IonButtons>
                </IonCard>
              );
            })}
      </IonRow>

      <IonItemDivider color="clear" />

      <IonButton
        expand="full"
        fill="outline"
        color="primary"
        routerLink="/album-create"
        style={artistItemDefaultStyle}
      >
        Create album
      </IonButton>

      <IonLoading
        isOpen={
          albumsStatus === "fetching" ||
          albumsStatus === "deleting" ||
          status === "fetching"
        }
      />
    </>
  );
};

export default React.memo(PersonalAlbums);
