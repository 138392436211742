import { firestore } from "firebase/app";
import { Artist, ArtistId } from "../models/Artist";
import { ArtistService } from "./ArtistService";
import { SearchService } from "./SearchService";

export class ProfileService {
  static changeProfile = (update: any, artistId: ArtistId): Promise<Artist> => {
    return new Promise<Artist>((resolve, reject) => {
      const db = firestore();
      const artistDBRef = db.collection("artists").doc(artistId);
      const artistDirectoryDBRef = db.collection("directory").doc(artistId);
      artistDirectoryDBRef.set(update, { merge: true }).then(() => {
        console.log("updated directory");
      });
      artistDBRef
        .set(update, { merge: true })
        .then(() => {
          console.log("updated artist profile");
          artistDBRef
            .get()
            .then((artistDoc) => {
              const artist = ArtistService.StronglyTypeArtistData(artistDoc);
              artist &&
                artist.alias &&
                SearchService.please().pushArtistToIndex(artist);
              resolve(artist);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };
}
