import create from "zustand";

export type TitleState = {
    title: string;
    setTitle: (title: string) => void
}

export const useTitle = create<TitleState>((set) => ({
    title: "sadfam",
    setTitle: (title) => {
        set({ title });
    }
}));