import React, { useEffect } from 'react';
import { useArtists } from '../data/store/useArtists';
import ArtistButton from './ArtistButton';






interface AsyncArtistButtonProps {
  id: string;
  variant?: string
  disabled?: boolean
  onClick?: any
  className?: any
};

const AsyncArtistButton: React.FC<AsyncArtistButtonProps> = (props) => {
  const { id } = props;
  const { fetch, shouldFetch } = useArtists();
  let artist = useArtists(x => x.retrieve(id));
  useEffect(() => {
    shouldFetch(id) && fetch(id);
  }, [fetch, id, shouldFetch, artist]);
  if (!artist) {
    return <></>
  }
  return (
    <ArtistButton {...props} artist={artist} />
  );
};


export default AsyncArtistButton;
