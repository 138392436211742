import React, { useState } from "react";
import Dropzone, { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { IonButton, IonIcon, IonText } from "@ionic/react";
import {
  closeOutline,
  musicalNotes,
  refreshCircleOutline,
} from "ionicons/icons";
import { ArtistId } from "../models/Artist";
import { MediaReference, Song } from "../models/Song";
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import { TransmissionService } from "../services/TransmissionService";

import "react-dropzone-uploader/dist/styles.css";

interface StateProps {
  ownerId?: ArtistId;
}

interface OwnProps {
  message?: string;
  collection: string;
  onComplete: (media: MediaReference) => void;
  onToggle?: () => void;
  initialAudio?: Song;
}

interface DispatchProps {}

interface UploaderProps extends OwnProps, StateProps, DispatchProps {}

const Uploader: React.FC<UploaderProps> = ({
  ownerId,
  collection,
  onComplete,
  message,
  onToggle = () => {},
  initialAudio = {},
}) => {
  const [fileExtension, setFileExtension] = useState("");
  const isInitialAudioExist = !isEmpty(initialAudio);
  const [isInitialMedia, setInitialMedia] =
    useState<boolean>(isInitialAudioExist);
  const [isShowDownloadInput, setIsShowDownloadInput] = useState<boolean>(
    !isInitialAudioExist
  );

  const renderTitle = () => {
    if (isShowDownloadInput) {
      return <IonText color="success">Return previous media</IonText>;
    } else {
      return (
        <>
          <IonIcon icon={musicalNotes} style={{ paddingRight: 10 }} />
          <IonText color="success">{`${initialAudio.title}.${get(
            initialAudio,
            "media.type",
            "mp3"
          )}`}</IonText>
        </>
      );
    }
  };

  const toggleEdit = () => {
    onToggle();
    setIsShowDownloadInput((prevState) => !prevState);
  };

  // specify upload params and url for your files
  const getUploadParams = async () => {
    const { fields, uploadUrl, fileUrl } =
      await TransmissionService.GeneratePreSignedURL(
        ownerId || "anonymous",
        collection,
        fileExtension
      );
    return { fields, meta: { fileUrl }, url: uploadUrl };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = (file: IFileWithMeta, status: StatusValue) => {
    const fileName = file && file.meta && file.meta.name;
    setInitialMedia(false);

    if (fileName) {
      const extension = fileName.split(".").pop();
      if (extension) {
        setFileExtension(extension);
      }
    }

    const type = file.file.type;
    const src = (file.meta as any).fileUrl;
    const media = { src, type } as MediaReference;
    if (status === "done") onComplete(media);
  };

  return (
    <>
      {isInitialMedia && (
        <IonButton
          onClick={toggleEdit}
          size="default"
          color="dark"
          fill="clear"
        >
          {renderTitle()}

          {isShowDownloadInput ? (
            <IonIcon
              icon={refreshCircleOutline}
              size="big"
              style={{ paddingLeft: 10 }}
            />
          ) : (
            <IonIcon
              icon={closeOutline}
              size="big"
              style={{ paddingLeft: 10 }}
            />
          )}
        </IonButton>
      )}

      {isShowDownloadInput && (
        <Dropzone
          maxFiles={1}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          canCancel={false}
          multiple={false}
          maxSizeBytes={104857600}
          inputContent={(message && message) || "Select media"}
          accept={
            collection === "songs"
              ? "audio/mp3, audio/wav, audio/*, video/mp4, video/*"
              : "image/*"
          }
        />
      )}
    </>
  );
};

export default Uploader;
