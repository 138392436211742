import {
  IonAvatar,
  IonButton,
  IonChip,
  IonImg,
  IonLabel,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import React from "react";
import HostConfig from "../HostConfig";
import { ArtistRef } from "../models/Directory";

interface ArtistButtonProps {
  artist: ArtistRef;
  variant?: string;
  disabled?: boolean;
  className?: any;
  onClick?: any;
}

const ArtistButton: React.FC<ArtistButtonProps> = (props) => {
  const { artist, variant, disabled, onClick } = props;
  const { avatar } = artist;
  const avatarElement = (
    <IonAvatar>
      <IonImg src={avatar || HostConfig.icon} onError={(e) => {}} alt="" />
    </IonAvatar>
  );
  //const avatarElement = <></>
  if (disabled && variant && variant === "alias") {
    return (
      <IonButton onClick={onClick} className="song-alias">
        {artist.alias}
      </IonButton>
    );
  }

  if (variant && variant === "message-alias") {
    return (
      <IonRouterLink routerLink={"/artist/" + artist.id}>
        <IonText className="alias" color="tertiary">
          {artist.alias}:
        </IonText>
      </IonRouterLink>
    );
  }

  if (disabled && variant && variant === "avatar") {
    return <>{avatarElement}</>;
  }

  if (variant && variant === "song-alias") {
    return (
      <IonRouterLink routerLink={"/artist/" + artist.id}>
        <IonText className="song-alias">{artist.alias}</IonText>
      </IonRouterLink>
    );
  }
  if (variant && variant === "mention") {
    return (
      <IonRouterLink routerLink={"/artist/" + artist.id}>
        <IonText className="mention-alias">@{artist.alias}</IonText>
      </IonRouterLink>
    );
  }

  if (variant && variant === "album") {
    return (
      <IonChip>
        <IonRouterLink routerLink={"/artist/" + artist.id}>
          <IonText className="mention-alias">{artist.alias}</IonText>
        </IonRouterLink>
      </IonChip>
    );
  }

  if (variant && variant === "alias") {
    return (
      <IonRouterLink routerLink={"/artist/" + artist.id}>
        <IonText className="alias" color="tertiary">
          {artist.alias}
        </IonText>
      </IonRouterLink>
    );
  }
  if (variant && variant === "avatar") {
    return (
      <IonRouterLink routerLink={"/artist/" + artist.id}>
        {avatarElement}
      </IonRouterLink>
    );
  }
  if (disabled) {
    return (
      <IonChip onClick={onClick}>
        {avatarElement}
        <IonLabel>{artist.alias}</IonLabel>
      </IonChip>
    );
  }

  return (
    <IonRouterLink routerLink={"/artist/" + artist.id}>
      <IonChip>
        {avatarElement}
        <IonLabel>{artist.alias}</IonLabel>
      </IonChip>
    </IonRouterLink>
  );
};

export default ArtistButton;
