import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonLabel, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import AsyncArtistButton from '../components/AsyncArtistButton';
import SongItem from '../components/SongItem';
import { Issue } from '../models/Issue';
import { IssueService } from '../services/IssueService';




const ReportItem: React.FC<Issue> = ({ id, title, authorId, description, path }) => {
    const item_id = path && path.split("/").pop();
    return <IonCard>
        <IonCardHeader>
            <IonChip color="danger">
                <AsyncArtistButton id={authorId} />
                {title}
            </IonChip>
        </IonCardHeader>
        <IonCardContent >
            {description}  {path}
            {path.includes("comments") && item_id && <SongItem id={item_id} />}
            <IonToolbar>
                <IonButtons>
                    <IonButton onClick={() => { IssueService.delete(id) }} fill="solid">
                        Resolved
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonCardContent>
    </IonCard>
}


const Reports: React.FC = (props) => {
    const [reports, setReports] = useState<Issue[]>([])

    useEffect(() => {
        IssueService.fetchAll().then((serverReports) => {
            setReports(serverReports)
        })
    })

    return (<>
        <IonCard>
            <IonCardHeader color="favorite">
                <IonCardTitle>
                    Issues
            </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>

                {reports.length === 0 && <IonLabel> No unresolved issues!</IonLabel>}
                {reports.map(report => <ReportItem key={report.id} {...report} />)}
            </IonCardContent>
        </IonCard>
    </>
    );
};

export default Reports;