import { IonButtons, IonCard, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonToggle } from '@ionic/react';
import React from 'react';
import { useConfig } from '../data/store/useConfig';
import { defaultConfiguration } from '../models/Configuration';





const Config: React.FC = (props) => {
    const { config, changeConfig } = useConfig()
    const { mission, transmitNotice, supportEmail, id, allowPublic, title, rules } = config || defaultConfiguration;
    const setData = (property: string, value: any) => {
        changeConfig(id, property, value);
    }
    return (<IonCard>
        <IonCardHeader color="favorite">
            <IonCardTitle>
                Configuration for ({id})
            </IonCardTitle>
        </IonCardHeader>
        <IonItem>
            <IonLabel color="favorite" position="floating">
                Title
            </IonLabel>
            <IonInput value={title} onIonChange={(e) => { setData("title", e.detail.value! || "") }}>

            </IonInput>
        </IonItem>

        <IonItem>
            <IonLabel color="favorite" position="floating">
                Mission
            </IonLabel>
            <IonInput value={mission} onIonChange={(e) => { setData("mission", e.detail.value! || "") }}>

            </IonInput>
        </IonItem>

        <IonItem>
            <IonLabel position="floating" color="favorite">
                Transmission Notice
                </IonLabel>
            <IonInput value={transmitNotice} onIonChange={(e) => { setData("transmitNotice", e.detail.value! || "") }}>

            </IonInput>
        </IonItem>
        <IonItem>
            <IonLabel position="floating" color="favorite">
                Rules
            </IonLabel>
            <IonInput value={rules} onIonChange={(e) => { setData("rules", e.detail.value! || "") }}>
            </IonInput>
        </IonItem>
        <IonItem>
            <IonLabel position="floating" color="favorite">
                Support Email
            </IonLabel>
            <IonInput value={supportEmail} onIonChange={(e) => { setData("supportEmail", e.detail.value! || "") }}>
            </IonInput>
        </IonItem>
        <IonItem>
            <IonButtons slot="start">
                <IonLabel position="floating">
                    Allow Public Group
            </IonLabel>

            </IonButtons>
            <IonButtons slot="end">
                <IonToggle onIonChange={(e) => {
                    setData("allowPublic", e.detail.checked ? "yes" : "no");
                }} checked={allowPublic === "yes"}>
                </IonToggle>
            </IonButtons>
        </IonItem>
    </IonCard >
    );
};



export default Config;
