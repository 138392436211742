import React, { FC, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonSpinner,
  useIonViewWillLeave,
} from "@ionic/react";
import { paperPlane } from "ionicons/icons";
import { useProfile } from "../data/store/useProfile";
import { ArtistSearchResult, useSearch } from "../data/store/useSearch";
import { ArtistId } from "../models/Artist";
import ArtistSelector from "./ArtistSelector";
import AsyncArtistButton from "./AsyncArtistButton";

interface MentionableInputProps {
  onTransmit: (input: string, mentions: string[]) => void;
  onActive: () => void;
  onInActive: () => void;
  status: "success" | "failure" | "idle" | "transacting";
  id: string;
}

const MentionableInput: FC<MentionableInputProps> = ({
  status,
  onActive,
  onInActive,
  onTransmit,
  id,
}) => {
  const { authorId } = useProfile();
  const { clear, search } = useSearch();
  const [text, setText] = useState("");
  const [mentioning, setMentioning] = useState(false);
  const inputRef = useRef<HTMLIonInputElement>(null);
  const [mentions, setMentions] = useState<ArtistId[]>([]);

  useIonViewWillLeave(() => {
    clear();
  });

  const onBeginMention = () => {
    setMentioning(true);
  };

  const onEndMention = (artist: ArtistSearchResult) => {
    const aliasFragment = "@" + text.split("@").pop() || "";
    setText(text.replace(aliasFragment, ""));
    setMentions([...mentions, artist.id]);
    setMentioning(false);
    inputRef.current && inputRef.current.focus({ preventScroll: false });
  };

  const handleTransmit = () => {
    onTransmit(text, mentions);
  };

  const onSuccess = () => {
    setText("");
    setMentions([]);
  };

  const onFailure = () => {};

  useEffect(() => {
    if (mentioning) {
      const query = text.split("@").pop();
      query && search(query, "artists");
    }
  }, [mentioning, search, text]);

  useEffect(() => {
    if (status === "success") {
      onSuccess();
    }
    if (status === "failure") {
      onFailure();
    }
  }, [status]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Backspace":
        const mentionsNumber = mentions.length;
        const textLength = text.length;
        const textArray = text.split(" ");

        if (textLength === 0 && mentionsNumber > 0) {
          //delete mentions if text value are empty
          setMentions((prevState) =>
            mentionsNumber === 1 ? [] : prevState.slice(0, -1)
          );
        }

        if (textLength > 0 && textArray[textArray.length - 1].includes("@")) {
          setMentioning(true);
        }
        break;
      case " ":
        onActive();
        if (mentioning) {
          setMentioning(false);
        }

        break;
      case "Enter":
        handleTransmit();
        break;
      case "@":
        onBeginMention();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {id !== "" && authorId && (
        <IonItem lines="none" key={id + "-mentionable-input"} color="light">
          {mentions.map((mentionId) => (
            <AsyncArtistButton
              key={mentionId}
              variant="mention"
              id={mentionId}
            />
          ))}

          {status === "transacting" && text.length !== 0 ? (
            <IonSpinner name="dots" />
          ) : (
            <IonInput
              ref={inputRef}
              className="ion-input"
              style={{ padding: "10px", border: "none" }}
              onIonChange={(e) => {
                setText(e.detail.value!);
                if (!e.detail.value!.includes("@" || "@@")) {
                  setMentioning(false);
                }
              }}
              value={text}
              key={id + "-fresh-comment"}
              onBlur={onInActive}
              onKeyDown={handleKeyDown}
              placeholder="comment..."
            ></IonInput>
          )}

          {text && (
            <IonButton onClick={handleTransmit} slot="end" fill="clear">
              <IonIcon icon={paperPlane} />
            </IonButton>
          )}
        </IonItem>
      )}

      {mentioning && (
        <IonItem lines="none">
          <ArtistSelector
            hideMembers
            onSelect={(artist) => {
              onEndMention(artist);
            }}
            hideSearch
            placeholder="mention"
            members={mentions}
            setMembers={setMentions}
          />
        </IonItem>
      )}
    </>
  );
};

export default MentionableInput;
