import firebase from "firebase";



class P2PAudioService {
    private remoteStream!: MediaStream;
    private localStream!: MediaStream;
    private localStreamElement!: HTMLAudioElement;
    private remoteStreamElement!: HTMLAudioElement;
    private peerConnection!: RTCPeerConnection;
    private roomId!: string;

    private configuration = {
        iceServers: [
            {
                urls: [
                    'stun:stun1.l.google.com:19302',
                    'stun:stun2.l.google.com:19302',
                ],
            },
        ],
        iceCandidatePoolSize: 10,
    };
    public static please(): P2PAudioService {
        if (!P2PAudioService.instance)
            P2PAudioService.instance = new P2PAudioService();
        return P2PAudioService.instance;
    }
    setStream(type: "local" | "remote", stream: MediaStream) {
    }
    private static instance: P2PAudioService;

    private async createRoom(roomId: string, ownerId: string) {
        const db = firebase.firestore();
        const roomRef = await db.collection('ice').doc(roomId);

        console.log('Create PeerConnection with configuration: ', this.configuration);
        this.peerConnection = new RTCPeerConnection(this.configuration);

        this.registerPeerConnectionListeners();

        this.localStream.getTracks().forEach(track => {
            this.peerConnection.addTrack(track, this.localStream);
        });

        // Code for collecting ICE candidates below
        const callerCandidatesCollection = roomRef.collection('callerCandidates');

        this.peerConnection.addEventListener('icecandidate', event => {
            if (!event.candidate) {
                console.log('Got final candidate!');
                return;
            }
            console.log('Got candidate: ', event.candidate);
            callerCandidatesCollection.add(event.candidate.toJSON());
        });
        // Code for collecting ICE candidates above

        // Code for creating a room below
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);
        console.log('Created offer:', offer);

        const roomWithOffer = {
            'offer': {
                type: offer.type,
                sdp: offer.sdp,
            },
        };
        await roomRef.set(roomWithOffer);
        this.roomId = roomRef.id;
        console.log(`New room created with SDP offer. Room ID: ${roomRef.id}`);
        // Code for creating a room above

        this.peerConnection.addEventListener('track', event => {
            console.log('Got remote track:', event.streams[0]);
            event.streams[0].getTracks().forEach(track => {
                console.log('Add a track to the remoteStream:', track);
                this.remoteStream.addTrack(track);
            });
        });

        // Listening for remote session description below
        roomRef.onSnapshot(async snapshot => {
            const data = snapshot.data();
            if (!this.peerConnection.currentRemoteDescription && data && data.answer) {
                console.log('Got remote description: ', data.answer);
                const rtcSessionDescription = new RTCSessionDescription(data.answer);
                await this.peerConnection.setRemoteDescription(rtcSessionDescription);
            }
        });
        // Listening for remote session description above

        // Listen for remote ICE candidates below
        roomRef.collection('calleeCandidates').onSnapshot(snapshot => {
            snapshot.docChanges().forEach(async change => {
                if (change.type === 'added') {
                    let data = change.doc.data();
                    console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`);
                    await this.peerConnection.addIceCandidate(new RTCIceCandidate(data));
                }
            });
        });
        // Listen for remote ICE candidates above
    }


    public registerPeerConnectionListeners() {
        this.peerConnection.addEventListener('icegatheringstatechange', () => {
            console.log(
                `ICE gathering state changed: ${this.peerConnection.iceGatheringState}`);
        });

        this.peerConnection.addEventListener('connectionstatechange', () => {
            console.log(`Connection state change: ${this.peerConnection.connectionState}`);
        });

        this.peerConnection.addEventListener('signalingstatechange', () => {
            console.log(`Signaling state change: ${this.peerConnection.signalingState}`);
        });

        this.peerConnection.addEventListener('iceconnectionstatechange ', () => {
            console.log(
                `ICE connection state change: ${this.peerConnection.iceConnectionState}`);
        });
    }
    public async joinOrCreate(roomId: string, ownerId: string) {
        const db = firebase.firestore();
        const roomRef = db.collection('ice').doc(`${roomId}`);
        const roomSnapshot = await roomRef.get();
        if (roomSnapshot.exists) {
            return this.joinRoomById(roomId, ownerId);
        } else {
            return this.createRoom(roomId, ownerId);
        }
    }
    private async joinRoomById(roomId: string, ownerId: string) {

        const db = firebase.firestore();
        const roomRef = db.collection('ice').doc(`${roomId}`);
        const roomSnapshot = await roomRef.get();
        console.log('Got room:', roomSnapshot.exists);

        if (roomSnapshot.exists) {
            console.log('Create PeerConnection with configuration: ', this.configuration);
            this.peerConnection = new RTCPeerConnection(this.configuration);
            this.registerPeerConnectionListeners();
            this.localStream.getTracks().forEach(track => {
                this.peerConnection.addTrack(track, this.localStream);
            });

            // Code for collecting ICE candidates below
            const calleeCandidatesCollection = roomRef.collection('calleeCandidates');
            this.peerConnection.addEventListener('icecandidate', event => {
                if (!event.candidate) {
                    console.log('Got final candidate!');
                    return;
                }
                console.log('Got candidate: ', event.candidate);
                calleeCandidatesCollection.add(event.candidate.toJSON());
            });
            // Code for collecting ICE candidates above

            this.peerConnection.addEventListener('track', event => {
                console.log('Got remote track:', event.streams[0]);
                event.streams[0].getTracks().forEach(track => {
                    console.log('Add a track to the remoteStream:', track);
                    this.remoteStream.addTrack(track);
                });
            });

            // Code for creating SDP answer below
            const { offer } = (roomSnapshot && roomSnapshot.data()) || { offer: "error" };
            console.log('Got offer:', offer);
            if (offer === "error") {
                throw new Error("Bad Offer");
            }
            await this.peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
            const answer = await this.peerConnection.createAnswer();
            console.log('Created answer:', answer);
            await this.peerConnection.setLocalDescription(answer);

            const roomWithAnswer = {
                answer: {
                    type: answer.type,
                    sdp: answer.sdp,
                },
            };
            await roomRef.update(roomWithAnswer);
            // Code for creating SDP answer above

            // Listening for remote ICE candidates below
            roomRef.collection('callerCandidates').onSnapshot(snapshot => {
                snapshot.docChanges().forEach(async change => {
                    if (change.type === 'added') {
                        let data = change.doc.data();
                        console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`);
                        await this.peerConnection.addIceCandidate(new RTCIceCandidate(data));
                    }
                });
            });
            // Listening for remote ICE candidates above
        }
    }
    public async openUserMedia(localAudioInput: HTMLAudioElement, remoteAudioOutput: HTMLAudioElement) {
        this.localStreamElement = localAudioInput
        this.remoteStreamElement = remoteAudioOutput;
        const stream = await navigator.mediaDevices.getUserMedia(
            { video: false, audio: true });
        this.localStream = stream;

        this.localStreamElement.srcObject = this.localStream;
        this.remoteStream = new MediaStream();
        this.remoteStreamElement.srcObject = this.remoteStream;
    }
    public async hangUp() {
        const tracks = this.localStream.getTracks();
        tracks.forEach(track => {
            track.stop();
        });

        if (this.remoteStream) {
            this.remoteStream.getTracks().forEach(track => track.stop());
        }

        if (this.peerConnection) {
            this.peerConnection.close();
        }

        this.remoteStreamElement.srcObject = null;
        this.localStreamElement.srcObject = null;
        // Delete room on hangup
        if (this.roomId) {
            const db = firebase.firestore();
            const roomRef = db.collection('ice').doc(this.roomId);
            const callerCandidates = await roomRef.collection('callerCandidates').get();
            callerCandidates.forEach(async candidate => {
                await candidate.ref.delete();
            });
            await roomRef.delete();
        }
    }








    private constructor() {

    }



}
export default P2PAudioService;


