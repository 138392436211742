import algoliasearch, { SearchIndex } from "algoliasearch";
import { ArtistSearchResult, SongSearchResult } from "../data/store/useSearch";
import { Artist } from "../models/Artist";
import { Song, SongId } from "../models/Song";

const artistsIndex = "artist";
const songsIndex = "song";

interface ArtistSearchProps {
  nbPages: number;
  artists: ArtistSearchResult[];
}

interface SongSearchProps {
  nbPages: number;
  songs: SongSearchResult[];
}

interface MultiSearchProps {
  nbPages: number;
  artists: ArtistSearchResult[];
  songs: SongSearchResult[];
}

export class SearchService {
  private static instance: SearchService;
  private algolia = algoliasearch(
    "ZL63XIDYO3",
    "ac2ff020aed198f0aed5dc09b76b9f55"
  );
  private artists: SearchIndex;
  private songs: SearchIndex;

  private constructor() {
    this.artists = this.algolia.initIndex(artistsIndex);
    this.songs = this.algolia.initIndex(songsIndex);
  }

  public static please(): SearchService {
    if (!SearchService.instance) SearchService.instance = new SearchService();
    return SearchService.instance;
  }

  search(query: string): Promise<ArtistSearchResult[]> {
    return new Promise((resolve, reject) => {
      this.artists
        .search(query)
        .then(({ hits }) => {
          console.log(hits);
          const artistRefs = hits.map(
            (x) => x as unknown as ArtistSearchResult
          );
          if (artistRefs.length !== 0) {
            resolve(artistRefs);
          }
        })
        .catch(reject);
    });
  }

  searchArtists(query: string, page: number): Promise<ArtistSearchProps> {
    return new Promise((resolve, reject) => {
      this.artists
        .search(query, { page })
        .then(({ hits, nbPages }) => {
          const artistRefs = hits.map(
            (x) => x as unknown as ArtistSearchResult
          );
          if (artistRefs.length !== 0) {
            resolve({
              nbPages,
              artists: artistRefs,
            });
          }
        })
        .catch(reject);
    });
  }

  searchSongs(query: string, page: number): Promise<SongSearchProps> {
    return new Promise((resolve, reject) => {
      this.songs
        .search(query, { page })
        .then(({ hits, nbPages }) => {
          const songRefs = hits.map((x) => x as any as SongSearchResult);
          if (songRefs.length !== 0) {
            resolve({
              nbPages,
              songs: songRefs,
            });
          }
        })
        .catch(reject);
    });
  }

  searchMulti(query: string, page: number): Promise<MultiSearchProps> {
    return new Promise((resolve, reject) => {
      const queries = [
        {
          indexName: artistsIndex,
          query: query,
          params: { hitsPerPage: 1000 },
        },
        {
          indexName: songsIndex,
          query: query,
          params: { page },
        },
      ];
      this.algolia
        .search(queries)
        .then(({ results }) => {
          const artistsResult = results.find(
            (result) => result.index === artistsIndex
          );
          const songsResult = results.find(
            (result) => result.index === songsIndex
          );
          const artists = artistsResult
            ? artistsResult.hits.map((x) => x as any as ArtistSearchResult)
            : [];
          const songs = songsResult
            ? songsResult.hits.map((x) => x as any as SongSearchResult)
            : [];
          const nbPages = songsResult ? songsResult.nbPages : 0;
          resolve({
            nbPages,
            artists,
            songs,
          });
        })
        .catch(reject);
    });
  }

  pushArtistToIndex(artist: Artist) {
    const { id, alias, avatar } = artist;
    if (alias.includes("https://")) {
      return;
    }
    console.log("add artist to search index" + artist.alias);
    this.artists.saveObject({ id, alias, avatar, objectID: id });
  }

  pushSongToIndex(song: Song) {
    const { id, title, authors } = song;
    const artists = authors.map((x) => song.artists[x]);
    this.songs.saveObject({ id, title, artists, objectID: id });
  }

  removeSongFromIndex(id: SongId) {
    this.songs.deleteObject(id);
  }
}
