import create from "zustand";
import P2PAudioService from "../../services/P2PAudioService";
import { SpaceMembers, SpaceService } from "../../services/SpaceService";
import handleError from "../../util/handleError";
export type SpaceDirectory = {
    members?: SpaceMembers;
    spaceId?: string,
    join: (spaceId: string, ownerId: string) => void
    leave: (spaceId: string, ownerId: string) => void
}

export const useSpaces = create<SpaceDirectory>((set, store) => ({
    join: (spaceId, ownerId) => {
        const onUpdate = (members: SpaceMembers) => {
            set({ members });
        }
        SpaceService.join(spaceId, ownerId, "lobby", onUpdate).then(members => {
            P2PAudioService.please().joinOrCreate(spaceId, ownerId).then(() => {
                set({ members, spaceId })
            }).catch(handleError);
        }).catch(handleError);

    }, leave: (spaceId, ownerId) => {
        SpaceService.leave(spaceId, ownerId).then(() => {
            P2PAudioService.please().hangUp().then(() => {
                set({ members: {}, spaceId: undefined });
            }).catch(handleError);
        }).catch(handleError);
    }
}));