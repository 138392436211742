import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTextarea,
} from "@ionic/react";
import { alertOutline, musicalNoteOutline } from "ionicons/icons";

import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useToasts } from "../data/store/useToasts";
import { useTransmission } from "../data/store/useTransmission";
import { useSongs } from "../data/store/useSongs";

import ArtistSelector from "./ArtistSelector";
import Content from "./Content";
import HashTagify from "./Hashtagify";
import SongItem from "./SongItem";
import Uploader from "./Uploader";

import { ArtistId } from "../models/Artist";
import { usePageTitle } from "../util/sugar";
import "react-dropzone-uploader/dist/styles.css";

interface TransmitProps {}

const Transmit: React.FC<TransmitProps> = () => {
  const { authorId, ownerId } = useProfile();
  const {
    todaysTransmission,
    isValid,
    setAuthors,
    fetchTodaysTransmission,
    setArt,
    setMedia,
    setDescription,
    setTitle,
    transmit,
    clearTodayTransmission,
  } = useTransmission();
  const [authors, setSongAuthors] = useState<ArtistId[]>([]);
  const { post } = useToasts();
  const { delete: deleteSong } = useSongs();
  const config = useConfig((x) => x.config);
  const [showArt, setShowArt] = useState(false);
  const transmitNotice = config && config.transmitNotice;

  usePageTitle("Transmit a Song");

  useEffect(() => {
    ownerId && fetchTodaysTransmission(ownerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authorId && !authors.includes(authorId)) {
      setSongAuthors((oldAuthors) => [...oldAuthors, authorId]);
    } else {
      setAuthors(authors);
    }
  }, [authorId, setSongAuthors, authors, setAuthors]);

  return (
    <Content className="chill">
      {!todaysTransmission && ownerId && (
        <IonRow>
          <IonCol sizeMd="6" pushMd="3">
            {transmitNotice && (
              <IonCard color="light">
                <IonCardContent color="medium">
                  <HashTagify>{transmitNotice}</HashTagify>
                </IonCardContent>
              </IonCard>
            )}

            <IonList className="inner-chill">
              {authorId && authors.includes(authorId) && (
                <ArtistSelector
                  placeholder={"Collaborators"}
                  fieldName={"Authors"}
                  members={authors}
                  setMembers={setSongAuthors}
                ></ArtistSelector>
              )}

              <IonItem>
                <IonLabel position="stacked" color="primary">
                  Title
                </IonLabel>
                <IonInput
                  spellCheck={false}
                  autocapitalize="off"
                  onIonChange={(e) => setTitle(e.detail.value!)}
                  required
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked" color="primary">
                  Description
                </IonLabel>
                <IonTextarea
                  maxlength={2000}
                  onIonChange={(e) => setDescription(e.detail.value!)}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked" color="primary">
                  Media
                </IonLabel>

                <Uploader
                  collection={"songs"}
                  onComplete={(media) => {
                    if (media) {
                      setMedia(media);
                    }
                  }}
                />
              </IonItem>

              <IonItem lines="none">
                {showArt && (
                  <IonLabel
                    onClick={() => setShowArt(false)}
                    position="stacked"
                    color="primary"
                  >
                    Art
                  </IonLabel>
                )}

                {showArt ? (
                  <Uploader
                    message="select an image file"
                    collection={"covers"}
                    onComplete={(media) => {
                      if (media) {
                        setArt(media);
                      }
                    }}
                  />
                ) : (
                  <IonButton
                    fill="clear"
                    expand="full"
                    onClick={() => {
                      setShowArt((x) => !x);
                    }}
                  >
                    {" "}
                    {!showArt && "Add"} Artwork
                  </IonButton>
                )}
              </IonItem>
            </IonList>

            <IonRow>
              <IonCol>
                <IonButton
                  disabled={!isValid()}
                  color={isValid() ? "favorite" : "primary"}
                  onClick={() => {
                    post({
                      id: "new-song",
                      message: "Song Transmitting",
                      icon: musicalNoteOutline,
                    });
                    transmit(ownerId);
                  }}
                  expand="block"
                >
                  Transmit
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      )}

      {todaysTransmission && (
        <IonRow>
          <IonCol sizeMd="6" pushMd="3">
            <SongItem id={todaysTransmission} />
            <IonButton
              color="danger"
              onClick={() => {
                deleteSong(todaysTransmission);
                clearTodayTransmission();
                post({
                  id: `new-song ${todaysTransmission}`,
                  message: "Song Revoked",
                  icon: alertOutline,
                });
              }}
              expand="block"
            >
              Revoke
            </IonButton>
          </IonCol>
        </IonRow>
      )}
    </Content>
  );
};

export default Transmit;
