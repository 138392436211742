import * as linkify from 'linkifyjs';
import Linkify from 'linkifyjs/react';
import React from "react";
import { useHistory } from 'react-router';
const hashtag = require('linkifyjs/plugins/hashtag');
hashtag(linkify);


const HashTagify: React.FC = (props) => {
    const history = useHistory();
    return <Linkify options={{
        attributes: (match) => {
            let props: Record<string, any> = {}
            
            if (match.startsWith("#")) {
                props["onClick"] = () => {
                    history.push("/tag/" + match.slice(1));
                };
            } else {
                props["target"] = "_blank";
            }

            return props;
        },
        formatHref: (match) => {
            if (match.startsWith("#")) {
                // eslint-disable-next-line no-script-url
                return "javascript:void(0)";
            } else {
                return match;
            }
            // eslint-disable-next-line no-script-url
        }
    }}>
        {props.children}
    </Linkify >
}
export default HashTagify;