import firebase from "firebase";
import create from "zustand";
import { Role } from "../../models/Role";

export type RoleState = {
    role?: Role
    status: "idle" | "observing"
    observe: (ownerId: string) => void
    set: (ownerId: string, role: Role) => void,
}


export const useRoles = create<RoleState>((set, get) => ({
    role: undefined,
    status: "idle",
    observe: (ownerId) => {
        set({ status: "observing" })
        const db = firebase.firestore()
        db.collection("roles").doc(ownerId).onSnapshot((doc) => {
            const role = { ...doc.data(), id: doc.id } as Role;
            set({ role })
        })
        //        GroupService.observe(ownerId)
    },
    set: (ownerId, role) => {
        const db = firebase.firestore()
        db.collection("roles").doc(ownerId).set(role);
    }
}));