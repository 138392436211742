import create from "zustand";

export type PlayerState = {
    isPaused: boolean
    playNow: () => void,
    pauseNow: () => void,
}


export const usePlayer = create<PlayerState>((set) => ({
    isPaused: true,
    playNow: () => {
        set({ isPaused: false })
    }, pauseNow: () => {
        set({ isPaused: true })
    }
}));