import { IonCard, IonCardContent } from "@ionic/react";
import React from "react";
import { ArtistId } from "../models/Artist";

const RequestAccessCard: React.FC<{ authorId?: ArtistId; message?: string }> =
  ({ message }) => {
    const defaultMessage =
      "You haven't joined any groups yet. To join a group, find the invitation link & passcode to your group (this was sent out in an email from School of Song and also is listed on your course information page).";

    return (
      <IonCard>
        <IonCardContent>{message || defaultMessage}</IonCardContent>
      </IonCard>
    );
  };

export default RequestAccessCard;
