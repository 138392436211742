import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonList,
  IonMenuToggle,
  IonModal,
  IonProgressBar,
  IonLabel,
  IonRow,
  IonToast,
  IonToolbar,
  IonImg,
} from "@ionic/react";
import {
  listOutline,
  pause as pauseIcon,
  play as playIcon,
  playBack,
  playForward,
  closeOutline,
  musicalNotesOutline,
  chevronUp,
} from "ionicons/icons";

import { usePlayer } from "../data/store/usePlayer";
import { usePlaylist } from "../data/store/usePlaylist";
import { useSongs } from "../data/store/useSongs";
import { useToasts } from "../data/store/useToasts";

import MediaService from "../services/MediaService";
import useWindowSize from "../util/useWindowSize";
import HostConfig from "../HostConfig";
import { Song } from "../models/Song";
import AsyncArtistButton from "./AsyncArtistButton";
import Player from "./Player";

interface NowPlayingBottomBarProps {}

const NowPlayingBottomBar: React.FC<NowPlayingBottomBarProps> = () => {
  const { isPaused } = usePlayer();
  const { songs, nextSong, previousSong, nowPlayingId } = usePlaylist();
  const nowPlaying = useSongs((x) => nowPlayingId && x.retrieve(nowPlayingId));
  const { retrieve } = useSongs();
  const toasts = useToasts((x) => x.toasts);
  const removeToast = useToasts((x) => x.remove);

  const [firstLoad, setFirstLoad] = useState(true);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const { width } = useWindowSize();
  const isVideoElement = nowPlaying && nowPlaying.media.type.includes("video");
  const lg = width > 767;

  const closeModal = () => setShowModal(false);

  const setAndPlay = (song: Song) => {
    MediaService.please()
      .setSong(song)
      .then(() => {
        MediaService.please().play();
      });
  };

  const playNext = () => {
    const song = retrieve(nextSong());
    song && setAndPlay(song);
  };

  const playPrev = () => {
    const song = retrieve(previousSong());
    song && setAndPlay(song);
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      setInterval(() => {
        if (MediaService.please().getVideoElement()) {
          MediaService.please().updateBuffer();
          setProgress(MediaService.please().progress);
          setBuffer(MediaService.please().buffer);
        }
      }, 150);
    }
  }, [firstLoad]);

  return (
    <IonFooter slot="bottom">
      <IonToolbar style={{ paddingBottom: 10 }}>
        {!!toasts.length &&
          toasts.map((toast) => (
            <IonToast
              key={toast.id}
              isOpen={true}
              message={toast.message}
              duration={2000}
              position={"top"}
              onDidDismiss={() => removeToast(toast)}
            />
          ))}

        <IonButtons slot="primary" hidden={!isVideoElement}>
          <Player />
        </IonButtons>

        {lg ? (
          <>
            {nowPlaying && (
              <IonButtons slot="start">
                <IonButton routerLink={"/songs/" + nowPlaying.id}>
                  {nowPlaying.title}
                </IonButton>
                {nowPlaying.authors.map((author) => (
                  <AsyncArtistButton key={author} id={author} />
                ))}
              </IonButtons>
            )}

            <IonButtons slot="end">
              {nowPlaying && (
                <>
                  {songs.length > 1 && (
                    <IonButton onClick={playPrev}>
                      <IonIcon icon={playBack} />
                    </IonButton>
                  )}

                  {isPaused ? (
                    <IonButton onClick={() => MediaService.please().play()}>
                      <IonIcon icon={playIcon} />
                    </IonButton>
                  ) : (
                    <IonButton onClick={() => MediaService.please().pause()}>
                      <IonIcon icon={pauseIcon} />
                    </IonButton>
                  )}

                  {songs.length > 1 && (
                    <IonButton onClick={playNext}>
                      <IonIcon icon={playForward} />
                    </IonButton>
                  )}
                </>
              )}

              {songs.length > 0 && (
                <IonButton>
                  <IonMenuToggle menu="playlist">
                    <IonIcon icon={listOutline} />
                  </IonMenuToggle>
                </IonButton>
              )}
            </IonButtons>
          </>
        ) : (
          nowPlaying && (
            <>
              <IonItem
                lines="none"
                style={{ "--min-height": "38px" }}
                button
                detail={false}
                onClick={() => setShowModal(true)}
              >
                <IonIcon
                  icon={musicalNotesOutline}
                  size="small"
                  style={{ marginRight: 4 }}
                />
                <IonLabel className="ion-no-margin">
                  {nowPlaying.title}
                </IonLabel>
              </IonItem>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowModal(true)}>
                  <IonIcon icon={chevronUp} />
                </IonButton>
              </IonButtons>
            </>
          )
        )}
      </IonToolbar>

      {nowPlaying && (
        <IonProgressBar
          color="favorite"
          style={{
            position: "absolute",
            bottom: 0,
            padding: 0,
            height: 10,
            margin: 0,
            zIndex: 15,
          }}
          value={progress}
          buffer={buffer}
          onClick={(e) => {
            if (lg) {
              const eventWidthPercentage = e.clientX / window.innerWidth;
              MediaService.please().seek(eventWidthPercentage);
            }
          }}
        />
      )}

      <IonModal
        isOpen={!lg && showModal}
        swipeToClose
        onDidDismiss={closeModal}
      >
        <IonList>
          <IonToolbar color="secondary">
            <IonButtons slot="end">
              <IonButton color="dark" onClick={closeModal}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>

          {nowPlaying && (
            <>
              <IonItem lines="none">
                <IonButtons slot="start">
                  <IonButton routerLink={"/songs/" + nowPlaying.id}>
                    {nowPlaying.title}
                  </IonButton>
                </IonButtons>
              </IonItem>

              <IonItem lines="none">
                <IonButtons slot="start">
                  {nowPlaying.authors.map((author) => (
                    <AsyncArtistButton key={author} id={author} />
                  ))}
                </IonButtons>
              </IonItem>
            </>
          )}
        </IonList>

        <IonImg
          src={
            (nowPlaying && nowPlaying.art && nowPlaying.art.src) ||
            HostConfig.icon
          }
          style={{
            maxHeight: "50vh",
          }}
        />

        <IonItemDivider color="clear" />

        {nowPlaying && (
          <div style={{ paddingLeft: 24, paddingRight: 24 }}>
            <IonProgressBar
              color="favorite"
              style={{ height: 10 }}
              value={progress}
              buffer={buffer}
              onClick={(e) => {
                const eventWidthPercentage =
                  (e.clientX - 24) / e.currentTarget.clientWidth;
                MediaService.please().seek(eventWidthPercentage);
              }}
            />

            <IonGrid className="ion-margin-top">
              <IonRow className="ion-align-items-center ion-justify-content-around ion-padding-top">
                <IonButtons>
                  {songs.length > 1 && (
                    <IonButton
                      onClick={() => {
                        const song = retrieve(previousSong());
                        song &&
                          MediaService.please()
                            .setSong(song)
                            .then(() => {
                              MediaService.please().play();
                            });
                      }}
                    >
                      <IonIcon icon={playBack} size="large" />
                    </IonButton>
                  )}
                </IonButtons>

                <IonButtons>
                  {isPaused ? (
                    <IonButton onClick={() => MediaService.please().play()}>
                      <IonIcon icon={playIcon} size="large" />
                    </IonButton>
                  ) : (
                    <IonButton onClick={() => MediaService.please().pause()}>
                      <IonIcon icon={pauseIcon} size="large" />
                    </IonButton>
                  )}
                </IonButtons>

                <IonButtons>
                  {songs.length > 1 && (
                    <IonButton
                      onClick={() => {
                        const song = retrieve(nextSong());
                        song &&
                          MediaService.please()
                            .setSong(song)
                            .then(() => {
                              MediaService.please().play();
                            });
                      }}
                    >
                      <IonIcon icon={playForward} size="large" />
                    </IonButton>
                  )}
                </IonButtons>
              </IonRow>
            </IonGrid>
          </div>
        )}

        <IonToolbar>
          <IonButtons slot="end">
            {!!songs.length && (
              <IonButton onClick={closeModal}>
                <IonMenuToggle menu="playlist">
                  <IonIcon icon={listOutline} size="large" />
                </IonMenuToggle>
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonModal>
    </IonFooter>
  );
};

export default NowPlayingBottomBar;
