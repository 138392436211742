import firebase, { firestore } from "firebase/app";
import { FreshIssue, Issue } from "../models/Issue";

export class IssueService {
    static transmit = (freshIssue: FreshIssue) => {
        return new Promise<Issue>((resolve, reject) => {
            var db = firebase.firestore();
            db.collection('issues').add(freshIssue).then((issueRef) => {
                console.log(issueRef);
                issueRef.get().then((issueDoc) => {
                    if (issueDoc !== undefined) {
                        const issue = IssueService.StronglyTypeIssueData(issueDoc);
                        resolve(issue);
                    }
                })
            });
        });
    };
    static fetchAll = () => {
        return new Promise<Issue[]>((resolve, reject) => {
            var db = firebase.firestore();
            db.collection('issues').get().then(issueCollection => {
                const allIssues = issueCollection.docs.map(issueDoc => {
                    return IssueService.StronglyTypeIssueData(issueDoc);
                })
                resolve(allIssues);
            }).catch(reject);
        });
    }

    static fetch = (id: string) => {
        return new Promise<Issue>((resolve, reject) => {
            var db = firebase.firestore();
            db.collection('issues').doc(id).get().then(issueDoc => {
                const issue = IssueService.StronglyTypeIssueData(issueDoc);
                resolve(issue);
            }).catch(reject);
        });
    }


    static delete = (id: string): Promise<void> => {
        return new Promise<void>((resolve) => {
            var db = firebase.firestore();
            if (!id || id === "") {
                return;
            }
            db.collection('issues').doc(id).delete().then(issueDoc => {
                resolve();
            });
        })
    }
    static StronglyTypeIssueData = (issueDoc: firestore.QueryDocumentSnapshot<firestore.DocumentData> | firestore.DocumentSnapshot<firestore.DocumentData>) => {
        const issue_data = { ...issueDoc.data(), id: issueDoc.id } as Issue;
        return issue_data;
    }
}


