import {
  IonActionSheet,
  IonAlert,
  IonIcon,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { FC, useState } from "react";
import { CommentNode } from "../models/Comment";
import ArtistButton from "./ArtistButton";
import AsyncArtistButton from "./AsyncArtistButton";
import HashTagify from "./Hashtagify";
import {
  create,
  ellipsisHorizontalOutline,
  close,
  trash,
} from "ionicons/icons";
import { useProfile } from "../data/store/useProfile";
import { useComments } from "../data/store/useComments";

const MentionableCommentNode: FC<CommentNode> = (node) => {
  const { id, threadId, text, mentions, authorId, ownerId, alias } = node;
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const { deleteComment, updateComment } = useComments();
  const isOwner = useProfile.getState().ownerId === ownerId;
  const areIdsExist = !!id && !!threadId;
  const isCommentWithCorrectId = id !== threadId;

  if (text.length === 0) return <></>;

  return (
    <IonRow style={{ position: "relative" }}>
      <IonText className="comment ion-text-wrap">
        <ArtistButton
          variant="alias"
          artist={{ alias, id: authorId, ownerId }}
        />
        {mentions &&
          mentions.map((artistId) => (
            <AsyncArtistButton key={artistId} variant="mention" id={artistId} />
          ))}
        <HashTagify>{text}</HashTagify>
      </IonText>

      {isOwner && areIdsExist && isCommentWithCorrectId && (
        <IonIcon
          color="dark"
          style={{ position: "absolute", top: 2, right: 2, padding: 6 }}
          icon={ellipsisHorizontalOutline}
          onClick={() => setShowActionSheet(true)}
        />
      )}

      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[
          {
            text: "Delete",
            role: "destructive",
            icon: trash,
            handler: () => {
              setShowAlertDelete(true);
            },
          },
          {
            text: "Edit",
            icon: create,
            handler: () => {
              setShowAlertEdit(true);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            icon: close,
          },
        ]}
      />

      <IonAlert
        isOpen={showAlertDelete}
        onDidDismiss={() => setShowAlertDelete(false)}
        header="Confirm!"
        message={"Are you sure you want to remove <strong>comment</strong>?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              deleteComment(threadId, id);
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showAlertEdit}
        onDidDismiss={() => setShowAlertEdit(false)}
        header="Edit Comment"
        inputs={[
          {
            name: "comment",
            type: "text",
            id: "comment-id",
            value: text,
            placeholder: "Enter Comment",
          },
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Save",
            handler: (alertData) => {
              updateComment(threadId, alertData.comment, id);
            },
          },
        ]}
      />
    </IonRow>
  );
};
export default MentionableCommentNode;
