import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonItem, IonItemDivider, IonLabel, IonRow, IonText, IonTextarea, IonTitle } from '@ionic/react';
import React, { useState } from 'react';
import { useLocation, withRouter } from 'react-router';
import Content from '../components/Content';
import { useProfile } from '../data/store/useProfile';
import { FreshIssue } from '../models/Issue';
import { IssueService } from '../services/IssueService';
import { usePageTitle } from '../util/sugar';


const Issue: React.FC = () => {

    const [title, setTitle] = useState("");
    const [transmitted, setTrasmitted] = useState(false);
    const [description, setDescription] = useState("");
    const { authorId, authenticated, ownerId } = useProfile();
    const { pathname } = useLocation();

    const makeReport = () => {
        if (authorId && ownerId) {
            const issue: FreshIssue = { authorId, ownerId, description, title, path: pathname };
            IssueService.transmit(issue).then(() => {
                setTrasmitted(true);
            })
        }
    }
    usePageTitle("Report an Issue");


    return (
        <Content className='background-wall'>
            <IonGrid>
                <IonRow>
                    <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                        {authenticated ? <IonCard>
                            {!transmitted ? <IonCardContent>
                                <IonItem>
                                    <IonLabel position="stacked" color="primary">Title</IonLabel>
                                    <IonTextarea onIonChange={(e) => setTitle(e.detail.value!)} placeholder={"What are you reporting?"} color='dark'></IonTextarea>
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="stacked" color="primary">Description</IonLabel>
                                    <IonTextarea onIonChange={(e) => setDescription(e.detail.value!)} value={description} placeholder={description} color='dark'></IonTextarea>
                                </IonItem>
                                <IonButton disabled={!description && !title} onClick={makeReport}>
                                    Transmit Report
                                    </IonButton>
                            </IonCardContent> : <><IonCardHeader color="warning">
                                <IonCardTitle>
                                    <IonTitle >
                                        Report Received!
                                    </IonTitle>
                                </IonCardTitle>
                            </IonCardHeader><IonCardContent>

                                        <IonItemDivider color='clear' />
                                        <IonText>
                                            Thanks for helping us make Song-a-day better!
                                    </IonText>
                                        <IonItemDivider color='clear' />
                                        <IonLabel color="danger">
                                            {title}
                                        </IonLabel>
                                        <IonItemDivider color='clear' />
                                        <IonText>
                                            {description}
                                        </IonText>
                                    </IonCardContent></>}
                        </IonCard> : <IonCard>
                                <IonCardContent>
                                    <IonText>
                                        Please login to submit an issue
                                    </IonText>
                                    <IonButton routerLink={"/login"}>
                                        Login
                                </IonButton>
                                </IonCardContent>
                            </IonCard>}
                    </IonCol>
                </IonRow></IonGrid>
        </Content>
    );
};

export default withRouter(Issue);