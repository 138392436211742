import produce from "immer";
import create from "zustand";
import { Configuration } from "../../models/Configuration";
import { Group, GroupId } from "../../models/Group";
import { ConfigurationService } from "../../services/ConfigurationService";
import { GroupService } from "../../services/GroupService";

export type ConfigState = {
    groupConfigurations: Record<GroupId, Configuration>,
    membership: GroupId[],
    config?: Configuration;
    groups: Record<GroupId, Group>
    status: "unknown" | "observing",
    groupId: GroupId
    group?: Group
    changeGroup: (ownerId: string, groupId: string, property: string, value: any) => void
    changeConfig: (groupId: string, property: string, value: any) => void
    observe: (ownerId: string) => void
}


export const useConfig = create<ConfigState>((set, store) => ({
    groupId: "public",
    groupConfigurations: {},
    membership: [],
    groups: {},
    status: "unknown",
    changeGroup: (ownerId, groupId, prop, value) => {
        let update: Record<string, any> = {}
        update[prop] = value;
        update["ownerId"] = ownerId;
        GroupService.changeGroup(groupId, update);
    },
    changeConfig: (groupId, prop, value) => {
        let update: Record<string, any> = {}
        update[prop] = value;
        ConfigurationService.changeConfiguration(update, groupId);
    },
    observe: (ownerId) => {
        GroupService.observe(ownerId, (groupMemberShips) => {
            const groups = produce(store().groups, (draft) => {
                groupMemberShips.forEach(groupMembership => {
                    draft[groupMembership.id] = groupMembership;
                })
            })
            set({ groups });
        })
        ConfigurationService.observe((configs) => {
            const newState = produce(store(), (draft) => {

                configs.forEach(config => {
                    if (store().groupId === config.id) {
                        draft.config = config;
                    }

                    draft.groupConfigurations[config.id] = config;
                })
            })
            set({ ...newState });
        });
    }
}));