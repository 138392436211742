import firebase from "firebase";
import { Group, GroupId } from "../models/Group";
import { OwnerId } from "../models/User";

export class GroupService {

    static observe = (ownerId: OwnerId, onGroupAccessGranted: (groups: Group[]) => void) => {
        const db = firebase.firestore();
        db.collection("groups").where("members", "array-contains", ownerId).onSnapshot((groupDoc) => {
            const groups = groupDoc.docs.map(x => GroupService.StronglyTypeGroupData(x))
            onGroupAccessGranted(groups);
        })
        db.collection("groups").where("ownerId", "==", ownerId).onSnapshot((groupDoc) => {
            const groups = groupDoc.docs.map(x => GroupService.StronglyTypeGroupData(x))
            onGroupAccessGranted(groups);
        })

    }
    static changeGroup = (groupId: GroupId, update: any) => {
        return new Promise<Group>((resolve, reject) => {
            const db = firebase.firestore();
            const groupRef = db.collection('groups').doc(groupId);
            groupRef.set(update, { merge: true }).then(() => {
                groupRef.get().then(groupDoc => {
                    return resolve(groupDoc.data() as Group);
                }).catch(reject);
            }).catch(reject);
        });

    }

    static StronglyTypeGroupData = (groupDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
        const group_data = { ...groupDoc.data(), id: groupDoc.id } as Group;
        return group_data;
    }
}
