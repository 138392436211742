import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTitle } from "../data/store/useTitle";
import { Timestamp } from "../models/Timeline";
import { PlaylistOrder } from "../models/Artist";
import { Playlist } from "../models/Playlists";
import isEmpty from "lodash.isempty";

export const sanitize = (unsafe: string) => {
  return unsafe.toLowerCase().replace("javascript", "");
};

export const findHashtags = (text: string) => {
  const tags: RegExpMatchArray | "" | null = text && text.match(/#[\p{L}]+/giu);
  return (
    tags !== null &&
    tags !== "" &&
    tags.map((tag) => tag.slice(1).toLowerCase())
  );
};

export const usePageTitle = (pageTitle: string) => {
  const { setTitle, title } = useTitle();
  useEffect(() => {
    if (title !== pageTitle) {
      setTitle(pageTitle || "");
    }
  });
};

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const useIdParam = (props: React.PropsWithChildren<any>) =>
  props.id || props.history.location.pathname.split("/").pop();
export const getIdParamFromProps = useIdParam;

export const useSearchParam = (param: string) => {
  const { searchParams } = new URL(document.location as any);
  return searchParams.get(param);
};

export const useCssImage = (url: string | undefined): string => {
  if (url === undefined) {
    return "";
  }
  return url && "url(" + url + ") ";
};
export const usePathName = (props: React.PropsWithChildren<any>) =>
  capitalize(props.location.pathname.split("/")[1]);

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
const addBodyClass = (className: string) =>
  document.body.classList.add(className);
const removeBodyClass = (className: string) =>
  document.body.classList.remove(className);

export function useBodyClass(className: string) {
  useEffect(() => {
    // Set up
    addBodyClass(className);

    // Clean up
    return () => {
      removeBodyClass(className);
    };
  }, [className]);
}

export const compareTimestamps = (a: Timestamp, b: Timestamp) => {
  return a.seconds - b.seconds;
};
export const toTimestamp = (date: number) => {
  return {
    seconds: date / 1000,
    nanoseconds: 0,
  } as Timestamp;
};

export const toDate = (time: Timestamp, dateFormat = "DD/MM/YYYY") => {
  if (!time) {
    return;
  }
  const date = dayjs(toDateSeconds(time));

  return date.format(dateFormat);
};

export const toDateSeconds = (timestamp: Timestamp) => {
  return timestamp && timestamp.seconds && timestamp.seconds * 1000;
};

/**
 * Make an array unique
 * @param ray
 */
export const uniq = (ray: any[]) => {
  return [...new Set(ray)];
};

export const externalLink = (safe: string) => {
  if (safe.startsWith("http")) {
    return safe;
  } else {
    return "http://" + safe;
  }
};

export const changeArrayItemsOrder = (
  orderedArray: PlaylistOrder[] | undefined,
  arrayGroupedById: Record<string, Playlist>,
  array: Playlist[]
): Playlist[] => {
  if (isEmpty(orderedArray) || !orderedArray) return array;

  const playlistsWithOrderItems = orderedArray.map((item) => {
    if (arrayGroupedById[item.id]) {
      return { ...arrayGroupedById[item.id], order: item.order };
    }
    return null;
  });

  const allAvailablePlaylist = playlistsWithOrderItems.filter(
    (item) => item !== null
  );

  const intersectionPlaylists = array.filter((item) =>
    allAvailablePlaylist.every(
      (playlistItem) => item.id !== (playlistItem && playlistItem.id)
    )
  );

  return [...allAvailablePlaylist, ...intersectionPlaylists] as Playlist[];
};

export const forceDownload = (blob: any, filename: string) => {
  const link = document.createElement("a");
  link.download = filename;
  link.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadResource = (url: string, filename: string) => {
  console.log(url);

  fetch(url, {
    headers: new Headers({
      Origin: url,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const fileExt = url.slice(-3);

      console.log(fileExt);
      forceDownload(blobUrl, filename + "." + fileExt);
    })
    .catch((e) => console.error(e));
};
