import { IonCard, IonCardContent, IonCardHeader, IonCol, IonRow, IonTitle } from '@ionic/react';
import React from 'react';
import Content from '../components/Content';
import { useConfig } from '../data/store/useConfig';
import { defaultConfiguration } from '../models/Configuration';
import { usePageTitle } from '../util/sugar';
import './Mission.scss';


const Mission: React.FC = () => {
  const { config } = useConfig()
  const mission = config ? config.mission : defaultConfiguration.mission;
  const rules = config ? config.rules : defaultConfiguration.rules;
  const disclaimer = config ? config.disclaimer : defaultConfiguration.disclaimer;
  const title = config ? config.title : defaultConfiguration.title;
  const supportEmail = config ? config.supportEmail : defaultConfiguration.supportEmail;
  usePageTitle("Mission");
  return (
    <Content>
      <IonRow>
        <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
          <IonCard>
            <IonCardHeader >
              <IonTitle>Welcome to {title}</IonTitle>
            </IonCardHeader>
            <IonCardContent>
              <p>{mission}</p>
              <h4>{rules}</h4>
              <small>{disclaimer}</small>
              <p>
                Contact:
                <a href={"mailto:" + supportEmail}>{supportEmail}</a> for questions
              </p>
            </IonCardContent>
          </IonCard>
        </IonCol></IonRow>
    </Content>
  );
};

export default Mission;