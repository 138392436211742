import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
  IonTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import Content from "../components/Content";
import SongItem from "../components/SongItem";
import Uploader from "../components/Uploader";
import { useProfile } from "../data/store/useProfile";
import { useSongs } from "../data/store/useSongs";
import { useTransmission } from "../data/store/useTransmission";
import { MediaReference } from "../models/Song";
import { useIdParam, usePageTitle } from "../util/sugar";
import isEmpty from "lodash.isempty";
import { alertOutline } from "ionicons/icons";
import { useToasts } from "../data/store/useToasts";
import ArtistSelector from "../components/ArtistSelector";

const ReviseDetail: React.FC = (props) => {
  const id = useIdParam(props);
  const {
    shouldFetch,
    fetch,
    revise,
    revisions,
    delete: deleteSong,
  } = useSongs();
  const { ownerId, authorId } = useProfile((x) => x);
  const { post } = useToasts();
  const { fetchTodaysTransmission, clearTodayTransmission } = useTransmission();
  const todaysTransmission = useTransmission((x) => x.todaysTransmission);
  const song = useSongs((x) => x.retrieve(id));

  const [description, setDescription] = useState(
    (song && song.description) || ""
  );
  const [title, setRevisionTitle] = useState("");
  const [authors, setAuthors] = useState([""]);
  const [media, setMedia] = useState<MediaReference | undefined>();
  const reviseSong = () => {
    media && revise(id, description, media, title, authors);
  };

  const handleSetAuthors = (songAuthors: string[]) => {
    if (songAuthors.includes(authorId!)) {
      setAuthors(songAuthors);
    } else {
      setAuthors([authorId!, ...songAuthors]);
    }
  };

  usePageTitle("Revise Song");

  useEffect(() => {
    if (song && song.authors && isEmpty(authors[0])) {
      setAuthors(song.authors);
    }
  }, [authorId, authors, song]);

  useEffect(() => {
    ownerId && fetchTodaysTransmission(ownerId);
  }, [fetchTodaysTransmission, ownerId, revisions]);

  useEffect(() => {
    shouldFetch(id) && fetch(id);
  }, [id, shouldFetch, fetch, song]);

  return (
    <Content className="background-wall">
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            {song && !todaysTransmission && ownerId && (
              <IonCard>
                <IonCardHeader>
                  <IonItem>
                    <IonTitle>
                      <IonLabel position="stacked" color="primary">
                        Title
                      </IonLabel>
                      {song.title}
                    </IonTitle>
                  </IonItem>
                </IonCardHeader>

                <IonCardContent>
                  {!isEmpty(authors[0]) && (
                    <ArtistSelector
                      placeholder={"Collaborators"}
                      fieldName={"Authors"}
                      members={authors}
                      setMembers={handleSetAuthors}
                    />
                  )}

                  <IonItem>
                    <IonLabel position="stacked" color="primary">
                      Revision
                    </IonLabel>
                    <IonTextarea
                      onIonChange={(e) => setRevisionTitle(e.detail.value!)}
                      value={title}
                      placeholder={"(revision title)"}
                      color="dark"
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked" color="primary">
                      Description
                    </IonLabel>
                    <IonTextarea
                      onIonChange={(e) => setDescription(e.detail.value!)}
                      value={description}
                      placeholder={description}
                      color="dark"
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked" color="primary">
                      Song Revision
                    </IonLabel>
                    <Uploader
                      message="Select Song Revision file"
                      collection="songs"
                      onComplete={(media) => {
                        setMedia(media);
                      }}
                    />
                  </IonItem>
                  <IonButton routerLink={"/song/" + id}>Cancel</IonButton>
                  <IonButton
                    color={
                      !description && !title && !media ? "favorite" : undefined
                    }
                    disabled={isEmpty(title) || !media}
                    onClick={reviseSong}
                    className="ion-float-right"
                  >
                    Transmit Revision
                  </IonButton>
                </IonCardContent>
              </IonCard>
            )}

            {todaysTransmission && (
              <>
                {song && <SongItem id={todaysTransmission} />}

                <IonButton
                  onClick={() => {
                    deleteSong(todaysTransmission);
                    clearTodayTransmission();
                    post({
                      id: `new-song ${todaysTransmission}`,
                      message: "Song Revoked",
                      icon: alertOutline,
                    });
                  }}
                  expand="full"
                  color="danger"
                >
                  Revoke
                </IonButton>
              </>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default withRouter(ReviseDetail);
