import React, { useLayoutEffect, useState } from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { ItemReorderEventDetail } from "@ionic/core";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonRow,
  IonTitle,
  IonToggle,
  IonReorderGroup,
  IonToolbar,
  IonHeader,
  IonReorder,
  IonFooter,
} from "@ionic/react";
import { ellipsisHorizontalOutline } from "ionicons/icons";
import Content from "../components/Content";
import SongList from "../components/SongList";
import { usePlaylists } from "../data/store/usePlaylists";
import { useProfile } from "../data/store/useProfile";
import { useIdParam, usePageTitle } from "../util/sugar";
import RequestAccessCard from "../components/RequestAccessCard";
import { UpdatePlaylist } from "../components/UpdatePlaylist";
import CompactSongItem from "../components/CompactSongItem";
import ConfirmationAlert from "../components/ConfirmationAlert";

interface PlaylistDetailProps extends RouteComponentProps {}

const PlaylistDetail: React.FC<PlaylistDetailProps> = (props) => {
  const id = useIdParam(props);
  const { ownerId } = useProfile();
  const { fetch, status, removeFromPlaylist, updatePlaylist } = usePlaylists();
  const playlist = usePlaylists((x) => x.playlists[id]);
  const removePlaylist = usePlaylists((x) => x.delete);
  const playlistTitle = (playlist && playlist.title) || "";
  const isPrivatePlaylist =
    playlist && playlist.isPrivate && ownerId !== playlist.ownerId;
  const [isEditPlaylist, setIsEditPlaylist] = useState(false);
  const [alertState, setAlertState] = useState("");

  usePageTitle(playlistTitle);

  const deleteSongFromPlaylist = (songId: string) => {
    if (ownerId === playlist.ownerId) {
      removeFromPlaylist(id, songId).then();
    }
  };

  const deleteThePlaylist = async () => {
    await removePlaylist(id);
    props.history.push("/playlists");
  };

  const onSortPlaylists = async (
    event: CustomEvent<ItemReorderEventDetail>
  ) => {
    let items = [...playlist.songs];
    items = event.detail.complete(items);

    await updatePlaylist({ ...playlist, songs: items });
  };

  useLayoutEffect(() => {
    fetch(id);
    // eslint-disable-next-line
  }, []);

  if (isPrivatePlaylist) {
    return (
      <Content>
        <RequestAccessCard
          message={
            "You do not have permission to view the content of this page"
          }
        />
      </Content>
    );
  }

  if (!playlist) return <IonLoading isOpen={!playlist} />;

  return (
    <Content>
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            {playlist.ownerId === ownerId && (
              <IonRow class="ion-justify-content-between  ion-align-items-center ion-padding-horizontal">
                <IonButton
                  onClick={() => setAlertState("delete playlist")}
                  disabled={!isEditPlaylist}
                  color="danger"
                >
                  Delete Playlist
                </IonButton>

                <IonRow class="ion-align-items-center">
                  <IonLabel class="ion-margin-horizontal">
                    Edit Playlist
                  </IonLabel>
                  <IonToggle
                    checked={isEditPlaylist}
                    onClick={() => setIsEditPlaylist((prevState) => !prevState)}
                  />
                </IonRow>
              </IonRow>
            )}

            {isEditPlaylist && (
              <UpdatePlaylist
                playlist={playlist}
                onClose={() => setIsEditPlaylist(false)}
              />
            )}

            {!!playlist.songs.length ? (
              isEditPlaylist ? (
                <IonReorderGroup
                  disabled={false}
                  onIonItemReorder={onSortPlaylists}
                >
                  {playlist.songs.map((song) => {
                    return (
                      <IonToolbar key={song.id}>
                        <IonHeader>
                          <CompactSongItem id={song.id} />
                        </IonHeader>
                        <IonReorder slot="start" />
                        <IonFooter>
                          <IonButton
                            fill="outline"
                            color="danger"
                            expand="full"
                            onClick={() => setAlertState(`delete ${song.id}`)}
                          >
                            Delete
                          </IonButton>
                        </IonFooter>
                        <ConfirmationAlert
                          message="Do you want to delete this song from playlist ?"
                          onConfirm={() => deleteSongFromPlaylist(song.id)}
                          onDismiss={() => setAlertState("")}
                          isAlertOpen={alertState === `delete ${song.id}`}
                        />
                      </IonToolbar>
                    );
                  })}
                </IonReorderGroup>
              ) : (
                <SongList
                  status={status}
                  fetchMore={() => {}}
                  onDeleteSong={deleteSongFromPlaylist}
                  songRefs={playlist.songs}
                  hideFetchButton
                />
              )
            ) : (
              <IonCard>
                <IonCardHeader color="tertiary">
                  <IonTitle>No Songs Found!</IonTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItemDivider color="clear" />
                  Use the Song Menu Modal to add your songs to this playlist!
                  <IonItemDivider color="clear" />
                  The button for the song menu looks like this:
                  <IonIcon
                    size="large"
                    icon={ellipsisHorizontalOutline}
                    style={{ marginLeft: 10, verticalAlign: "middle" }}
                  />
                </IonCardContent>
              </IonCard>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>

      <ConfirmationAlert
        message="Do you want to delete this playlist ?"
        onConfirm={async () => {
          await deleteThePlaylist();
        }}
        onDismiss={() => {
          setAlertState("");
        }}
        isAlertOpen={alertState === "delete playlist"}
      />

      <IonLoading isOpen={status === "fetching"} />
    </Content>
  );
};

export default withRouter(PlaylistDetail);
